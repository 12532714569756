import { doc, setDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import { useFirestoreDocData } from 'reactfire';

import { Container } from '@mui/material';

import SettingsPanel from '../components/SettingsPanel/SettingsPanel';
import withAuth from '../components/withAuth';

import { SETTING_PARAMS as NOTIFY_SETTING } from '../components/SettingsPanel/InAppNotificationsSetting';
import { SETTING_PARAMS as CVSS_SETTING } from '../components/SettingsPanel/MinimumCvssScoreSetting';
import { SETTING_PARAMS as CISA_SETTING } from '../components/SettingsPanel/NotifyCisaVulnerabilitySetting';
import { SETTING_PARAMS as TOUR_SETTING } from '../components/SettingsPanel/ShowTourSetting';
import { memo } from 'react';

export const PATH = '/settings';

export const SettingsPage = ({
  loading,
  notificationsEnabled,
  setNotificationsEnabled,
  minimumCvss,
  setMinimumCvss,
  cisaNotificationsEnabled,
  setCisaNotificationsEnabled,
  showTour,
  setShowTour,
}) => {

  return (
    <Container>
      <SettingsPanel
        loading={loading}
        notificationsEnabled={notificationsEnabled}
        setNotificationsEnabled={setNotificationsEnabled}
        minimumCvss={minimumCvss}
        setMinimumCvss={setMinimumCvss}
        cisaNotificationsEnabled={cisaNotificationsEnabled}
        setCisaNotificationsEnabled={setCisaNotificationsEnabled}
        showTour={showTour}
        setShowTour={setShowTour}
      />
    </Container>
  );
};

const withData = (WrappedComponent) => memo(({ user, ...props }) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const { data = {}, status } = useFirestoreDocData(settingRef);

  const setValue = (field) => (e) => {
    setDoc(settingRef, { [field]: e }, { merge: true });
  };

  return (
    <WrappedComponent
      {...props}
      loading={status === 'loading'}
      notificationsEnabled={data.hasOwnProperty(NOTIFY_SETTING.name) ? data[NOTIFY_SETTING.name] : NOTIFY_SETTING.value}
      setNotificationsEnabled={setValue(NOTIFY_SETTING.name)}
      minimumCvss={data[CVSS_SETTING.name] || CVSS_SETTING.value}
      setMinimumCvss={setValue(CVSS_SETTING.name)}
      cisaNotificationsEnabled={data.hasOwnProperty(CISA_SETTING.name) ? data[CISA_SETTING.name] : CISA_SETTING.value}
      setCisaNotificationsEnabled={setValue(CISA_SETTING.name)}
      showTour={data.hasOwnProperty(TOUR_SETTING.name) ? data[TOUR_SETTING.name] : TOUR_SETTING.value}
      setShowTour={setValue(TOUR_SETTING.name)}
    />
  );
});

export default withAuth(withData(SettingsPage));