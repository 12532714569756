import PropTypes from 'prop-types';

export const settingType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  prettyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  value: PropTypes.any,
  type: PropTypes.oneOf(['boolean', 'string']),
});

export const settingGroupType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    settings: PropTypes.arrayOf(settingType).isRequired,
  })
);

export const CPEType = PropTypes.shape({
  part: PropTypes.oneOf(['a', 'h', 'o', '*']),
  vendor: PropTypes.string,
  product: PropTypes.string,
  // TODO: We might just deal with vendor and product, but if not, need to flesh this out.
});

const pageShape = {
  navText: PropTypes.string,
  navIcon: PropTypes.element,
  pageContent: PropTypes.element,
  navLink: PropTypes.string,
  pageTitle: PropTypes.string,
  disabled: PropTypes.bool,
  // TODO: Validate this is a path
  path: PropTypes.string,
};

pageShape.pages = PropTypes.arrayOf(PropTypes.shape(pageShape));

export const PageType = PropTypes.shape(pageShape);