import React, { useEffect, useState, } from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Paper,
  LinearProgress,
  Checkbox,
  alpha,
  TableSortLabel,
  Fade,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  loadingRow: {
    padding: 0,
  },
  errorRow: {
    padding: 0,
    height: 350,
  },
  errorIcon: {
    margin: 'auto',
    display: 'block',
    color: theme.palette.error.main,
  },
  checkbox: {
    padding: 0,
    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
      color: theme.palette.secondary.main,
    },
  },
  selected: {
    backgroundColor: alpha(theme.palette.secondary.main, .1),
  }
}));

export const PaginatedDataTable = (
  {
    data = [],
    dataConfig,
    rowsPerPage,
    page,
    totalRows,
    loading = false,
    error = false,
    updateConfig,
    dense = false,
    onRowClick = () => { },
    showPaginationControlls = true,
    multiselect = false,
    onChange = () => { },
    actions = [],
    actionButtonProps,
    sortable = false,
  }
) => {
  const styles = useStyles();
  const { fields, pagination } = dataConfig;
  const numColumns = dataConfig.fields.length + (multiselect ? 1 : 0) + (actions.length > 0 ? 1 : 0);
  const [selected, setSelected] = useState(Array(data.length).fill(false));

  useEffect(() => {
    setSelected(Array(data.length).fill(false));
  }, [data]);

  useEffect(() => {
    onChange(data.filter((item, idx) => selected[idx]));
  }, [selected, data, onChange]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="data table" size={dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {multiselect &&
              <TableCell
                style={{ borderBottom: 'none' }}
              >
                <Checkbox
                  className={styles.checkbox}
                  checked={data.length > 0 && selected.every((item) => item)}
                  indeterminate={!selected.every((item) => item) && !selected.every((item) => !item)}
                  onChange={() => {
                    if (selected.every((item) => item)) {
                      setSelected(selected.map(() => false));
                    } else {
                      setSelected(selected.map(() => true));
                    }
                  }}
                />
              </TableCell>
            }
            {dataConfig.fields.map((field, idx) => (
              <TableCell
                key={idx}
                align={field.align || "right"}
                style={{ borderBottom: 'none' }}
              >
                <TableSortLabel>
                <Box fontWeight="fontWeightBold">{field.label}</Box>
                </TableSortLabel>
              </TableCell>
            ))}
            {actions.length > 0 &&
              <TableCell style={{ borderBottom: 'none' }} />
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {error ?
            <TableRow>
              <TableCell colSpan={numColumns} className={styles.errorRow}>
                <ErrorOutlineIcon fontSize={'large'} colSpan={3} className={styles.errorIcon} />
              </TableCell>
            </TableRow>
            :
            <>
              <TableRow>
                <TableCell colSpan={numColumns} className={styles.loadingRow}>
                  <Fade in={loading}><div><LinearProgress colSpan="3" color={`secondary`} /></div></Fade>
                  {/*loading ?
                    <LinearProgress colSpan="3" color={`secondary`} /> :
                    <div style={{ height: 4 }} colSpan={3} />
                  */}
                </TableCell>
              </TableRow>
              {loading && (!data || data.length === 0) &&
                Array(rowsPerPage || pagination.defaultRowsPerPage).fill(Array(numColumns).fill('')).map((columns, idx) =>
                  <TableRow key={idx}>
                    {columns.map((col, idx) =>
                      <TableCell key={idx}>
                        <Skeleton />
                      </TableCell>
                    )}
                  </TableRow>
                )
              }
              {data.map((row, idx) => (
                <TableRow
                  key={idx}
                  className={`${selected[idx] && styles.selected}`}
                  hover
                  {...row.rowProps}
                  onClick={() => onRowClick(row)}
                >
                  {multiselect &&
                    <TableCell>
                      <Checkbox
                        className={styles.checkbox}
                        checked={selected[idx] || false}
                        onChange={() => setSelected([...selected.slice(0, idx), !selected[idx], ...selected.slice(idx + 1)])}
                      />
                    </TableCell>
                  }
                  {fields.map((field, idx) => (
                    <TableCell
                      key={idx}
                      align={field.align || "right"}
                    >
                      {row[field.fieldName]}
                    </TableCell>
                  ))}
                  {actions.length > 0 &&
                    <TableCell
                      align={`right`}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      {actions.map(({ name, onClick, Icon }, idx) =>
                        <Tooltip key={idx} title={`${name}`}>
                          <IconButton onClick={() => onClick()} {...actionButtonProps} size="large">
                            {Icon}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  }
                </TableRow>
              ))}
              {showPaginationControlls &&
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={pagination.rowsPerPageOptions}
                    count={totalRows || data?.length}
                    rowsPerPage={rowsPerPage || pagination.defaultRowsPerPage}
                    page={page || 0}
                    onPageChange={(e, p) => updateConfig({ page: p })}
                    onRowsPerPageChange={(e) => updateConfig({ rowsPerPage: e.target.value })}
                  />
                </TableRow>
              }
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaginatedDataTable.propTypes = {
  /**
   * The data to display
   */
  data: PropTypes.arrayOf(PropTypes.object),
  dataConfig: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'right']),
    })),
  }).isRequired,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  totalRows: PropTypes.number,
  updateConfig: PropTypes.func,
  dense: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  showPaginationControlls: PropTypes.bool,
  multiselect: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    Icon: PropTypes.element.isRequired,
  })),
  actionButtonProps: PropTypes.object,
  sortable: PropTypes.bool,
};

PaginatedDataTable.defaultProps = {
  data: [],
  updateConfig: () => { },
  dense: false,
  loading: false,
  error: false,
  showPaginationControlls: true,
  multiselect: false,
  onChange: () => { },
  actions: [],
  sortable: false,
};

export default PaginatedDataTable;