import {
  useUser,
} from 'reactfire';
import {
  serverTimestamp,
} from 'firebase/firestore';

import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';

import CVEDetails from '../../components/CVEDetails/CVEDetails';
import CommentSection from '../../components/CommentSection/CommentSection';

import { useParams } from 'react-router';
import { useCve, useCveComments } from '../../queries';

const useStyles = makeStyles((theme) => ({
  comments: {
    paddingTop: theme.spacing(2),
  },
}));

export const CVEDetailsPage = ({ cve, cveLoading, cveError, comments, commentsLoading, commentsError, uid, onSaveComment }) => {
  const classes = useStyles();

  // TODO: Error handle. Snackbar?
  if (cveError || (!cveLoading && !cve)) return (`Something went wrong and I haven't made great error handlers yet.`);

  return (
    <Container>
      <CVEDetails cve={cve} loading={cveLoading} error={cveError} />
      <div className={classes.comments}>
        <CommentSection
          comments={comments}
          loading={commentsLoading}
          error={commentsError}
          currentUser={uid}
          saveComment={onSaveComment}
        />
      </div>
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ ...props }) => {
  const { cveId } = useParams();
  const { data: user } = useUser();

  const [{ data: comments, status: commentsStatus, error: commentsError }, addComment] = useCveComments(cveId)
  const commentsLoading = commentsStatus === "loading";

  const { data: cve, status: cveStatus, error: cveError } = useCve(cveId);
  const cveLoading = cveStatus === "loading";

  const handleSaveComment = (message) => {
    const comment = {
      message: message,
      timestamp: serverTimestamp(),
      user: {
        _id: user?.uid,
        name: user?.displayName,
      }
    };

    // TODO: Left off here for the night. When the first comment is made, the query is not
    // tracking changes because the document didn't exist upon initial query. So I tried
    // to force the component to rerender after the change was made. But it isn't working.
    // I think it updates too fast. The useDatabaseListData query doesn't fetch new data unless 
    // something changes, and it doesnt get notifications since the key didn't exist when
    // the firdst render happened. It may be possible to prevent this by initializing the 
    // comments collection in a cloud function when a CVE is initially saved.
    addComment(comment);//.then(() => forceRerender({...update}));
  };

  return (
    <WrappedComponent
      {...props}
      cve={cve}
      cveLoading={cveLoading}
      cveError={cveError}
      comments={comments}
      commentsLoading={commentsLoading}
      commentsError={commentsError}
      uid={user?.uid}
      onSaveComment={handleSaveComment}
    />
  );
};

export default withData(CVEDetailsPage);