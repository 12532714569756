import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";

import isValidDomain from 'is-valid-domain';
//import { v4 } from "cidr-regex";
import { isIPv4 } from 'is-ip';

import XWSelect from "../../components/XWSelect";
import XWTextField from "../../components/XWTextField";
import XWButton from "../XWButton";
import { Stack } from "@mui/material";

export const AssetInput = ({ onAdd }) => {
  const [asset, setAsset] = useState('');
  const [type, setType] = useState('IPv4');
  const [error, setError] = useState();

  const options = useMemo(() => [
    {
      displayName: "IPv4 Address",
      value: "IPv4",
      placeholder: '127.0.0.1',
      validate: (ip) => isIPv4(ip),
      error: `Invalid IPv4 address`,
    },
    {
      displayName: "Domain Name",
      value: "DNS",
      placeholder: "example.com",
      validate: (domain) => isValidDomain(domain),
      error: `Invalid Domain Name`,
    },
    // {
    //   displayName: "CIDR Block",
    //   value: "CIDR",
    //   placeholder: "127.0.0.1/24",
    //   validate: (cidr) => v4({ exact: true }).test(cidr),
    //   error: `Invalid CIDR notation`,
    // },
  ], []);

  useEffect(() => {
    if (asset === '') return setError(null);
    const option = options.find(({ value }) => value === type);

    if (!option.validate(asset)) {
      setError(option.error);
    } else {
      setError(null);
    }
  }, [asset, setError, options, type]);

  return (
    <Stack sx={{alignItems: 'center'}}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: !!error ? '-15px' : 0 }}>
        <Box sx={{ flexGrow: 1, p: 1, maxWidth: 200 }}>
          <XWTextField
            fullWidth
            value={asset}
            onChange={(e) => setAsset(e.target.value)}
            placeholder={options.find(({ value }) => value === type)?.placeholder || ''}
            label={`Asset`}
            error={!!error}
            helperText={error}
          />
        </Box>
        <Box sx={{ p: 1, minWidth: 150 }}>
          <XWSelect
            options={options}
            value={type}
            onChange={(e) => setType(e.target.value)}
            label={`Type`}
          />
        </Box>
      </Box>
      <Box sx={{ maxWidth: 360, p: 2, alignSelf: 'flex-end', display: 'flex' }}>
        <XWButton
          onClick={() => {
            if(asset === '') return setError('Field must not be blank');
            onAdd({asset: asset, type: type});
            setAsset('');
          }}
          disabled={!!error}
        >
          Save
        </XWButton>
      </Box>
    </Stack>
  );
};

export default AssetInput;