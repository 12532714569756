import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import {
  red,
  green,
  yellow,
  orange,
  purple,
} from '@mui/material/colors';

const darkTheme = createTheme(adaptV4Theme({
  // typography: {
  //   fontFamily: [
  //     'monospace',
  //     'Roboto',
  //   ],
  // },
  palette: {
    mode: 'dark',
    primary: {
      main: purple[800],
    },
    secondary: {
      main: green[200]
    },
    severity: {
      low: green[200],
      medium: yellow[500],
      high: orange[500],
      critical: red[500],
    },
  },
}));

export default responsiveFontSizes(darkTheme);