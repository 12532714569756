import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import { 
  ThemeProvider, 
  StyledEngineProvider,
  CssBaseline, 
} from '@mui/material';
import mainTheme from '../src/themes/mainTheme';
import { BrowserRouter as Router } from 'react-router-dom';

import { FirebaseAppInitializer } from './util/FirebaseAppInitializer';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppInitializer>
      <SnackbarProvider maxSnack={3}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: { autoFocus: true, variant: 'contained' },
                cancellationButtonProps: { color: 'secondary' }
              }}
            >
              <Router>
                <CssBaseline />
                <App />
              </Router>
            </ConfirmProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </FirebaseAppInitializer>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();