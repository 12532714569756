
export const getDescription = (cve = {}) =>
  cve.cve?.description.description_data.find((item) => item.lang === 'en').value ||
  'No description found.';

export const getCvssScoreText = (cve = {}) =>
  cve.impact?.baseMetricV3?.cvssV3?.baseScore ||
  cve.impact?.baseMetricV2?.cvssV2?.baseScore ||
  '?';

export const getReferences = (cve = {}) =>
  cve.cve?.references?.reference_data ||
  [];

export const getCveId = (cve = {}) =>
  cve.cve?.CVE_data_meta.ID ||
  "NO-CVEID-FOUND";