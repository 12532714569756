import { memo } from 'react';
import {
  useSigninCheck,
} from 'reactfire';

import AppBarNotifications from './AppBarNotifications';
import AppBarUserOptions from "./AppBarUserOptions";

export const AppBarMenuItems = ({signedIn, user, loading}) => {

  return (
    <div style={{ display: 'flex' }}>
      {signedIn && <AppBarNotifications user={user} />}
      <AppBarUserOptions user={user} loading={loading} />
    </div>
  );
};

const withData = (WrappedComponent) => memo(({ ...props }) => {
  const { data: signInCheckResult = {}, status } = useSigninCheck();
  const { signedIn, user } = signInCheckResult;

  return (
    <WrappedComponent
      {...props}
      user={user}
      signedIn={signedIn}
      loading={status === 'loading'}
    />
  );
});

export default withData(AppBarMenuItems);