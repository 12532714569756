import PropTypes from 'prop-types';
import { Typography } from "@mui/material";

import XWAvatarMessage from "../XWAvatarMessage";

import XWCrossfade from '../XWCrossfade';
import CVETable from '../CVETable/CVETable';

import { getCvssScoreText, getDescription } from '../../util/parseCVE';

export const Notification = ({ loading = false, notification = {} }) => {
  const { title = '', body = '' } = notification;

  const getNotificationByType = (notification) => {
    const { type = '' } = notification;

    switch (type) {
      case 'cvelist':
        const { data } = notification;
        const { cves } = data;

        const formatData = (cves) => cves.map((cve) => (
          {
            id: cve.cve.CVE_data_meta.ID,
            cvss: getCvssScoreText(cve),
            description: getDescription(cve),
          }
        ));

        return (
          <CVETable
            data={formatData(cves)}
            showPaginationControlls={false}
          />
        );

      case 'message':
      default:
        return (<></>);
    }
  };

  return (
    <XWCrossfade
      loading={loading}
      FallbackComponent={<XWAvatarMessage message={`Loading notification...`} loading={true} />
      }
    >
      <>
        <Typography variant={`h4`}>
          {title}
        </Typography>
        <Typography variant={`body1`}>
          {body}
        </Typography>
        <div>{getNotificationByType(notification)}</div>
      </>
    </XWCrossfade>
  );
};

Notification.propTypes = {
  loading: PropTypes.bool,
  notification: PropTypes.object,
};

Notification.defaultProps = {
  loading: false,
};

export default Notification;