import React from 'react';

import {
  TreeView,
  TreeItem
} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const createNodes = ({ depth = 0, children = [], prefix = 'nodes' }) => {
  let keyCount = 0;

  return children.map(({ operator, negate, children, cpe_match }, idx) => {
    const nodeId = `${prefix}-${depth}-${keyCount++}`;
    return (
      <TreeItem key={idx} nodeId={`${nodeId}`} label={`${operator}`}>
        {children?.length > 0 && createNodes({ depth: depth + 1, children: children, prefix: nodeId })}
        {cpe_match?.length > 0 && cpe_match.map(({ cpe23Uri }, idx) =>
          <TreeItem key={`${nodeId}-${idx}`} nodeId={`${nodeId}-${idx}`} label={`${cpe23Uri}`} />)
        }
      </TreeItem>
    );
  })
};

export const CVEDetailsApplicabilitySection = ({ configurations = {} }) => {
  const classes = useStyles();

  return (
    <div>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        disableSelection
      >
        {createNodes({ children: configurations.nodes })}
      </TreeView>
    </div>
  );
};

export default CVEDetailsApplicabilitySection;