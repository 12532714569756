import {
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import CVETable from "../CVETable/CVETable";
import CVESearchResultsToolbar from "./CVESearchResultsToolbar";

import { CVESearchSearchBox } from "./CVESearchSearchBox";

export const SHOW_SIDEBAR_BREAKPOINT = 'lg';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

export const CVESearch = ({ data, rowsPerPage, page, totalRows, loading, error, updateConfig, onSearch = () => { }, onRowClick = () => {} }) => {
  const theme = useTheme();
  const classes = useStyles();
  const showSidebar = useMediaQuery(theme.breakpoints.up(SHOW_SIDEBAR_BREAKPOINT));

  return (
    <Grid
      className={classes.root}
      container
      spacing={3}
    >
      {showSidebar &&
        <Grid item>
          <CVESearchSearchBox
            onSearch={(v) => onSearch(v)}
          />
        </Grid>
      }
      <Grid item xs>
          <CVETable
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            totalRows={totalRows}
            loading={loading}
            onRowClick={onRowClick}
            error={error}
            updateConfig={updateConfig}
            Toolbar={
              <CVESearchResultsToolbar
                onSearch={(v) => onSearch(v)}
              />
            }
          />
      </Grid>
    </Grid>
  );
};

export default CVESearch;