import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useSnackbar } from 'notistack';

import {
  Container,
} from "@mui/material";

import AuthenticationPanel from "../components/authentication/AuthenticationPanel";

export const PATH = '/login';

export const LoginPage = ({
  onLoginWithProvider,
  onSignupWithEmail,
  onLoginWithEmail,
  onForgotPassword,
}) => {

  return (
    <Container>
      <AuthenticationPanel
        onLoginWithProvider={onLoginWithProvider}
        onSignupWithEmail={onSignupWithEmail}
        onLoginWithEmail={onLoginWithEmail}
        onForgotPassword={onForgotPassword}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ ...props }) => {
  const auth = getAuth();
  const { enqueueSnackbar } = useSnackbar();

  const loginWithProvider = (provider) =>
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('you are logged in');
      }).catch((error) => {
        console.log(error.message);
      });


  const signupWithEmail = ({ email, password }) =>
    createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        enqueueSnackbar(`${error}`, { variant: 'error' })
      });

  const signinWithEmail = ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        enqueueSnackbar(`${error}`, { variant: 'error' })
      });


  // TODO: Do better.
  const forgotPassword = () => {
    enqueueSnackbar(`I'm really sorry but I haven't implemented this yet.`);
  };

  return (
    <WrappedComponent
      {...props}
      onLoginWithProvider={loginWithProvider}
      onSignupWithEmail={signupWithEmail}
      onLoginWithEmail={signinWithEmail}
      onForgotPassword={forgotPassword}
    />
  );
};

export default withData(LoginPage);