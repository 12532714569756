import React, {
  useState,
} from 'react';

import {
  Accordion,
  AccordionDetails,
  Typography,
} from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    width: '30%',
    minWidth: 128,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  accordion: {
    filter: 'brightness(80%)',
  },
  accordionSummary: {
    overflow: 'hidden',
  },
  accordionPreview: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
}));

export const XWAccordion = ({ title, preview, content, loading, disabled }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const AccordionSummary = withStyles({
    content: {
      overflow: 'hidden',
    }
  })(MuiAccordionSummary);

  return (
    <Accordion
      className={classes.accordion}
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      disabled={loading || disabled}
    >
      <AccordionSummary
        content={{ overflow: 'hidden' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={`${classes.accordionTitle} ${!preview && classes.fullWidth}`}>
          {loading ? <Skeleton /> : title}
        </Typography>
        {preview &&
          <Typography className={classes.accordionPreview}>
            {loading ? <Skeleton style={{ width: '100%' }} /> : preview}
          </Typography>
        }
      </AccordionSummary>
      <AccordionDetails>
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

export default XWAccordion;