import { doc, setDoc } from "@firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const useSetting = (user, setting) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const {data, loading, error} = useFirestoreDocData(settingRef)
  
  const setValue = (field) => (e) => {
    setDoc(settingRef, { [field]: e }, { merge: true });
  };

  return [{data: data?.hasOwnProperty(setting.name) ? data[setting.name] : setting.value, loading, error}, setValue(setting)]
};

export default useSetting;