import {
  Card,
  CardHeader,
  IconButton,
  MobileStepper,
  useMediaQuery,
} from "@mui/material";
import {
  makeStyles,
  useTheme,
} from "@mui/styles";

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';

import XWButton from "../XWButton";
import XWUserAvatar from "../XWUserAvatar";
import XWAvatarMessage from "../XWAvatarMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
  },
  content: {
    padding: theme.spacing(2),
  },
  stepper: {
    backgroundColor: 'inherit',
  }
}));

export const XWIntroTooltip = ({
  continuous,
  index,
  isLastStep,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  // TODO: If we ever want SSR, this will bork it
  const temporaryDrawer = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { content } = step;
  const { body, title } = content;

  return (
    <Card
      className={classes.root}
      {...tooltipProps}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{variant:'h5' }}
        action={
          <IconButton {...closeProps}>
            <CloseIcon />
          </IconButton>
        }
        avatar={
          temporaryDrawer && <XWUserAvatar />
        }
      />
      <div>
        <div className={classes.content}>
          { !temporaryDrawer && <XWAvatarMessage />}
          {body}
        </div>
        <div>
          <MobileStepper
            className={classes.stepper}
            variant={`progress`}
            LinearProgressProps={{ color: 'secondary', sx: { marginLeft: 1, marginRight: 1 } }}
            steps={size | 0}
            position={`static`}
            activeStep={index}
            nextButton={
              isLastStep ?
                <XWButton
                  size={`small`}
                  {...closeProps}
                >
                  Close
                </XWButton>
                :
                <XWButton
                  endIcon={<NavigateNextIcon />}
                  size={`small`}
                  {...primaryProps}
                >
                  Next
                </XWButton>
            }
            backButton={
              <XWButton
                startIcon={<NavigateBeforeIcon />}
                type={`secondary`}
                size={`small`}
                disabled={step === 0}
                {...backProps}
              >
                Back
              </XWButton>
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default XWIntroTooltip;