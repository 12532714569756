import { Stack } from '@mui/material';
import GoogleSigninButton from './GoogleSigninButton';

export const LoginProviders = ({ onLoginWithProvider, ...props }) => {
  return (
    <Stack {...props}>
      <GoogleSigninButton onLoginWithProvider={onLoginWithProvider} />
    </Stack>
  );
};

export default LoginProviders;