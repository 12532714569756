import { Paper } from '@mui/material';
import PaginatedDataTable from "../../sharedcomponents/PaginatedDataTable";
import XWToolbar from "../XWToolbar";
import { openPortsTable } from "../../config/tables";

export const OpenPortsTable = ({ ports, ContainerProps, ...props }) => {

  return (
    <Paper {...ContainerProps}>
      <XWToolbar title={'Open Ports'} />
      <PaginatedDataTable
        {...props}
        data={ports}
        dataConfig={openPortsTable}
        showPaginationControlls={false}
      />
    </Paper>
  );
};

export default OpenPortsTable;