import { Fab, useMediaQuery, useTheme } from "@mui/material";

export const SHOW_WIDE_FAB_BREAKPOINT = 'sm';

export const XWFab = ({ Icon = null, expandable = true, text, ...props }) => {
  const theme = useTheme();
  const wideFab = useMediaQuery(theme.breakpoints.up(SHOW_WIDE_FAB_BREAKPOINT));

  const extended = expandable && wideFab && text;

  return (
    <Fab
      variant={`${extended ? 'extended' : 'circular'}`}
      color={`primary`}
      aria-label={'add-to-inventory'}
      {...props}
    >
      <Icon sx={{mr: extended ? 1 : 0}} />
      {extended && text}
    </Fab>
  );
};

export default XWFab;