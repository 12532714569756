import { doc, setDoc } from '@firebase/firestore';
import { useFirestore, } from 'reactfire';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useFirestoreDocData } from 'reactfire';
import DynamicSetting from "./DynamicSetting";

export const SETTING_PARAMS = {
  name: "cisa_notify",
  prettyName: "CISA Notifications",
  description: "Get notified when CISA marks a vulnerability as actively exploited.",
  type: 'boolean',
  icon: <AccountBalanceIcon />,
  value: false,
};

export const NotifyCisaVulnerabilitySetting = ({ loading, cisaNotificationsEnabled, setCisaNotificationsEnabled }) => {
  return (
    <DynamicSetting
      loading={loading}
      value={cisaNotificationsEnabled}
      setValue={setCisaNotificationsEnabled}
      setting={SETTING_PARAMS}
    />
  );
};

export const withCisaVulnerability = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const { data = {}, status } = useFirestoreDocData(settingRef);
  const value = data[SETTING_PARAMS.name] || SETTING_PARAMS.value;

  const setValue = (e) => {
    setDoc(settingRef, { [SETTING_PARAMS.name]: e }, { merge: true });
  };

  return (
    <WrappedComponent
      {...props}
      cisaNotificationsEnabled={value}
      setCisaNotificationsEnabled={setValue}
      loading={status === 'loading'}
    />
  );

};

export default NotifyCisaVulnerabilitySetting;