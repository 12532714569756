import {
  AnalyticsProvider,
  FirebaseAppProvider,
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  useFirebaseApp,
  FunctionsProvider,
} from 'reactfire';
import {
  getAuth,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  getAnalytics,
} from 'firebase/analytics';
import {
  getDatabase,
  connectDatabaseEmulator,
} from 'firebase/database';
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getPerformance } from "firebase/performance";

import {
  EMULATOR_AUTH,
  EMULATOR_DATABASE,
  EMULATOR_DATABASE_PORT,
  EMULATOR_FIRESTORE,
  EMULATOR_FIRESTORE_PORT,
  EMULATOR_FUNCTIONS,
  EMULATOR_FUNCTIONS_PORT,
  firebaseConfig,
} from '../config/constants';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';

const FirebaseComponents = ({ children }) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const db = getDatabase(app);
  const fs = getFirestore(app);
  const func = getFunctions(app);
  const ga = getAnalytics(app);
  getPerformance(app);

  if (process.env.NODE_ENV !== 'production') {
    connectAuthEmulator(auth, EMULATOR_AUTH);
    connectDatabaseEmulator(db, EMULATOR_DATABASE, EMULATOR_DATABASE_PORT);
    connectFirestoreEmulator(fs, EMULATOR_FIRESTORE, EMULATOR_FIRESTORE_PORT);
    connectFunctionsEmulator(func, EMULATOR_FUNCTIONS, EMULATOR_FUNCTIONS_PORT);
  }

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={db}>
        <FirestoreProvider sdk={fs}>
          <FunctionsProvider sdk={func}>
            <AnalyticsProvider sdk={ga}>
              {children}
            </AnalyticsProvider>
          </FunctionsProvider>
        </FirestoreProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
};

export const FirebaseAppInitializer = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseComponents>
      {children}
    </FirebaseComponents>
  </FirebaseAppProvider>
);