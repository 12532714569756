import makeStyles from '@mui/styles/makeStyles';
import PaginatedDataTable from '../../sharedcomponents/PaginatedDataTable';

import { cveTableConfig } from '../../config/tables';
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export const CVETable = ({ Toolbar = null, ...props }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {Toolbar}
      <PaginatedDataTable
        {...props}
        dataConfig={cveTableConfig}
      />
    </Paper>
  );
};

export default CVETable;