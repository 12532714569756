import React from 'react';

import { List, ListSubheader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InAppNotificationsSetting from './InAppNotificationsSetting';
import MinimumCvssScoreSettingStories from './MinimumCvssScoreSetting';
import NotifyCisaVulnerabilitySetting from './NotifyCisaVulnerabilitySetting';
import ShowTourSetting from './ShowTourSetting';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    margin: 'auto',
  },
}));

export const SettingsPanel = ({ 
  loading = false, 
  notificationsEnabled, 
  setNotificationsEnabled,
  minimumCvss,
  setMinimumCvss,
  cisaNotificationsEnabled,
  setCisaNotificationsEnabled,
  showTour,
  setShowTour,
 }) => {
  const classes = useStyles();

  return (
      <List className={classes.root}>
        <ListSubheader>Notification Settings</ListSubheader>
        <InAppNotificationsSetting loading={loading} notificationsEnabled={notificationsEnabled} setNotificationsEnabled={setNotificationsEnabled} />
        <MinimumCvssScoreSettingStories loading={loading} minimumCvss={minimumCvss} setMinimumCvss={setMinimumCvss} />
        <NotifyCisaVulnerabilitySetting loading={loading} cisaNotificationsEnabled={cisaNotificationsEnabled} setCisaNotificationsEnabled={setCisaNotificationsEnabled} />
        <ShowTourSetting loading={loading} showTour={showTour} setShowTour={setShowTour} />
      </List>
  );
};

// SettingsPanel.propTypes = {
//   settingsGroup: settingGroupType,
// };

// SettingsPanel.defaultProps = {
// };

export default SettingsPanel;