export const firebaseConfig = {
  apiKey: "AIzaSyC3vSo5xLsb-cQeVfr6_oZUJm_8q_sVQdg",
  authDomain: "xploit--world.firebaseapp.com",
  projectId: "xploit--world",
  storageBucket: "xploit--world.appspot.com",
  messagingSenderId: "565253854335",
  appId: "1:565253854335:web:0ef6236673fb1b084a1b4a",
  measurementId: "G-F8VY0B135Q"
};

export const vapidKey = 'BP83V3XjRGZ6TGuhGDf_ReRJuoroleyeAHHp7PhKQGV-Qfx_8-fZ9davLjKk0tAJiWYYzvY0VyZ6DkPvC47YLYA';

const DEFAULT_EMULATOR_DATABASE = 'localhost';
export const EMULATOR_DATABASE = process.env.REACT_APP_EMULATOR_DATABASE || process.env.EMULATOR_DATABASE || DEFAULT_EMULATOR_DATABASE;
const DEFAULT_EMULATOR_DATABASE_PORT = 9000;
export const EMULATOR_DATABASE_PORT = process.env.REACT_APP_EMULATOR_DATABASE_PORT || process.env.EMULATOR_DATABASE_PORT || DEFAULT_EMULATOR_DATABASE_PORT;

const DEFAULT_EMULATOR_FIRESTORE = 'localhost';
export const EMULATOR_FIRESTORE = process.env.REACT_APP_EMULATOR_FIRESTORE || process.env.EMULATOR_FIRESTORE || DEFAULT_EMULATOR_FIRESTORE;
const DEFAULT_EMULATOR_FIRESTORE_PORT = 8080;
export const EMULATOR_FIRESTORE_PORT = process.env.REACT_APP_EMULATOR_FIRESTORE_PORT || process.env.EMULATOR_FIRESTORE_PORT || DEFAULT_EMULATOR_FIRESTORE_PORT;

const DEFAULT_EMULATOR_AUTH = 'http://localhost:9099';
export const EMULATOR_AUTH = process.env.REACT_APP_EMULATOR_AUTH || process.env.EMULATOR_AUTH || DEFAULT_EMULATOR_AUTH;

const DEFAULT_EMULATOR_FUNCTIONS = 'localhost';
export const EMULATOR_FUNCTIONS = process.env.REACT_APP_EMULATOR_FUNCTIONS || process.env.EMULATOR_FUNCTIONS || DEFAULT_EMULATOR_FUNCTIONS;
const DEFAULT_EMULATOR_FUNCTIONS_PORT = 5001;
export const EMULATOR_FUNCTIONS_PORT = process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT || process.env.EMULATOR_FUNCTIONS_PORT || DEFAULT_EMULATOR_FUNCTIONS_PORT;