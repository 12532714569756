import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  Switch,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  TextField,
  Tooltip,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Skeleton } from '@mui/material';
import XWCrossfade from '../XWCrossfade';

export const FloatSetting = ({ name, prettyName, description, icon, value, setValue, textFieldProps }) => {
  const [newValue, setNewValue] = useState(value);

  return (
    <ListItem>
      {icon &&
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      }
      <TextField
        fullwidth={'true'}
        style={{ width: '100%' }}
        label={prettyName}
        value={newValue}
        placeholder={'https://example.com'}
        // TODO: This doesnt work great. Play with it. Try 7.5AA and see what happens in the emulator
        onChange={(e) => setNewValue(e.target.value)}
        onBlur={() => setValue(parseFloat(newValue))}
        {...textFieldProps}
      />
      <ListItemSecondaryAction>
        <Tooltip title={description}>
          <Icon edge="end" aria-label="details">
            <HelpOutlineIcon />
          </Icon>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

FloatSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    value: PropTypes.bool,
  }),
};

FloatSetting.defaultProps = {
};

export const BoolSetting = ({ name, prettyName, description, icon, value, setValue }) => {

  return (
    <ListItem>
      {icon &&
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      }
      <ListItemText id={`setting-label-${name}`} primary={prettyName} secondary={description} />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={() => setValue(!value)}
          checked={value}
          inputProps={{ 'aria-labelledby': `setting-label-${name}` }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

BoolSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    value: PropTypes.bool,
  }),
};

BoolSetting.defaultProps = {
};

export const StringSetting = ({ name, prettyName, description, placeholder = '', onChange, icon, value, textFieldProps }) => {
  const [newValue, setNewValue] = useState(value);

  return (
    <ListItem>
      {icon &&
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      }
      <TextField
        {...textFieldProps}
        fullwidth={'true'}
        style={{ width: '100%' }}
        label={prettyName}
        value={newValue}
        placeholder={placeholder}
        onChange={(e) => setNewValue(e.target.value)}
        onBlur={() => onChange({ name: newValue })}
      />
      <ListItemSecondaryAction>
        <Tooltip title={description}>
          <Icon edge="end" aria-label="details">
            <HelpOutlineIcon />
          </Icon>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

StringSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    value: PropTypes.string,
  }),
};

StringSetting.defaultProps = {
  placeholder: '',
};

export const DynamicSetting = ({ setting, value, setValue, loading = false }) => {

  const selectType = (setting) => {
    switch (setting.type) {
      case "boolean":
        return (
          <BoolSetting {...setting} value={value} setValue={setValue} />
        );
      case "string":
        return (
          <StringSetting {...setting} value={value} setValue={setValue} />
        );
      case "float":
        return (
          <FloatSetting {...setting} value={value} setValue={setValue} />
        );
      default:
        return (<div>Incompatible setting type.</div>)
    }
  };

  return (
    <XWCrossfade
      loading={loading}
      FallbackComponent={
        <ListItem>
          <ListItemAvatar>
            <Skeleton variant={`circle`}>
              <Avatar />
            </Skeleton>
          </ListItemAvatar>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      }
    >
      <div id={`setting-${setting?.name}`}>{selectType(setting)}</div>
    </XWCrossfade>
  );

};

DynamicSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    value: PropTypes.any,
    type: PropTypes.oneOf(['boolean', 'string', 'float']),
  }),
  loading: PropTypes.bool,
};

DynamicSetting.defaultProps = {
  loading: false,
};

export default DynamicSetting;