export const cveSearchTableConfig = {
  fields: [
    {
      label: "CVE ID",
      fieldName: 'id',
      align: 'left',
    },
    {
      label: "CVSS",
      fieldName: 'cvss',
    },
    {
      label: "Description",
      fieldName: 'description',
    },
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};

export const cveTableConfig = {
  fields: [
    {
      label: "CVE ID",
      fieldName: 'id',
      align: 'left',
    },
    {
      label: "CVSS",
      fieldName: 'cvss',
    },
    {
      label: "Description",
      fieldName: 'description',
    },
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};

export const cpeTableConfig = {
  fields: [
    {
      label: "Vendor",
      fieldName: 'vendor',
    },
    {
      label: "Product",
      fieldName: 'product',
    },
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};

export const openPortsTable = {
  fields: [
    {
      label: "Port",
      fieldName: 'port',
      align: "left",
    },
    {
      label: "Protocol",
      fieldName: 'protocol',
      align: "left",
    },
    {
      label: "Service",
      fieldName: 'service',
      align: "left",
    },
    {
      label: "Status",
      fieldName: 'status',
      align: 'left',
    }
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};

export const assetListTable = {
  fields: [
    {
      label: "Asset",
      fieldName: 'asset',
      align: "left",
    },
    {
      label: "Type",
      fieldName: 'type',
      align: "left",
    },
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};

export const hostListTable = {
  fields: [
    {
      label: "Address",
      fieldName: 'address',
      align: "left",
    },
  ],
  pagination: {
    rowsPerPageOptions: [5, 10, 25, 100],
    defaultRowsPerPage: 10,
  },
};