import {
  useState,
} from 'react';
import {
  Dialog,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useSnackbar } from 'notistack';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import XWUserAvatar from "../XWUserAvatar";
import AuthenticationPanel from '../authentication/AuthenticationPanel';

export const AppBarUserOptions = ({
  user = null,
  loading = false,
  onLoginWithProvider,
  onSignupWithEmail,
  onLoginWithEmail,
  onForgotPassword, logOut,
}) => {
  const [loginDialog, setLoginDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Dialog
        open={loginDialog}
        fullScreen
      >
        <AuthenticationPanel
          onLoginWithProvider={(provider) => onLoginWithProvider(provider).then(() => setLoginDialog(false))}
          onSignupWithEmail={(creds) => onSignupWithEmail(creds).then(() => setLoginDialog(false))}
          onLoginWithEmail={(creds) => onLoginWithEmail(creds).then(() => setLoginDialog(false))}
          onForgotPassword={onForgotPassword}
        />
      </Dialog>
      <XWUserAvatar
        userId={user?.uid}
        id={`app-bar-user-options`}
        loading={loading}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{ backgroundColor: 'black', margin: '4px' }}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {user ?
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              logOut();
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={`Log out`} />
          </MenuItem>
          :
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setLoginDialog(true);
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={`Login`} />
          </MenuItem>
        }
      </Menu>
    </>
  )
};

export const withData = (WrappedComponent) => ({ ...props }) => {
  const auth = getAuth();
  const { enqueueSnackbar } = useSnackbar();

  const loginWithProvider = (provider) =>
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('you are logged in');
      }).catch((error) => {
        console.log(error.message);
      });


  const signupWithEmail = ({ email, password }) =>
    createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        enqueueSnackbar(`${error}`, { variant: 'error' })
      });

  const signinWithEmail = ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        enqueueSnackbar(`${error}`, { variant: 'error' })
      });

  // TODO: Do better.
  const forgotPassword = () => {
    enqueueSnackbar(`I'm really sorry but I haven't implemented this yet.`);
  };

  const logOut = () =>
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });

  return (
    <WrappedComponent
      {...props}
      onLoginWithProvider={loginWithProvider}
      onSignupWithEmail={signupWithEmail}
      onLoginWithEmail={signinWithEmail}
      onForgotPassword={forgotPassword} logOut={logOut}
    />
  );
}

export default withData(AppBarUserOptions);