import { useState } from 'react';
import {
  getPerformance,
  trace,
} from 'firebase/performance';

export const useTrace = (name) => {
  const perf = getPerformance();
  const [t] = useState(trace(perf, name))

  return t;
};

export default useTrace;