import {
  useFirestore,
  useFirestoreDocData,
} from 'reactfire';
import {
  doc,
} from 'firebase/firestore';
import { useTrace } from '../util/useTrace';
import { useEffect } from 'react';

export const useCve = (cveId) => {
  const fs = useFirestore();
  const t = useTrace('getCve');

  const cveRef = doc(fs, `cves`, cveId);
  const cve = useFirestoreDocData(cveRef, { idField: "_id" })
  const { status } = cve;

  useEffect(() => {
    if (status === 'loading') {
      t.start();
      console.log(`start: ${status}`)
    } 

    return () => {
      if (status === 'loading') {
        t.stop();
        console.log(`stop: ${status}`);
      }
    }
  }, [status, t])

  return cve;
};