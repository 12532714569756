import { useEffect, useState, } from 'react';
import { useAnalytics, useSigninCheck } from "reactfire";
import {
  Outlet,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import ComputerIcon from '@mui/icons-material/Computer';
import RadarIcon from '@mui/icons-material/Radar';

import AppBarMenuItems from "./components/AppBarMenuItems/AppBarMenuItems";
import MainNavigation from "./sharedcomponents/MainNavigation";

import CPEInventoryPage, {
  PATH as INVENTORY_PATH,
} from './pages/CPEInventoryPage/CPEInventoryPage';

import CVEPage, {
  PATH as CVE_PATH,
} from './pages/CVEPage/CVEPage';

import SettingsPage, {
  PATH as SETTINGS_PATH,
} from './pages/SettingsPage';

import LoginPage, {
  PATH as LOGIN_PATH,
} from './pages/LoginPage';

import NotificationPage, {
  PATH as NOTIFICATION_PATH,
} from "./pages/NotificationPage/NotificationPage";

import AssetsPage, {
  PATH as ASSETS_PATH
} from './pages/AssetsPage/AssetsPage';

import HostPage, {
  PATH as HOST_PATH
} from './pages/HostPage/HostPage';


import XWAppDrawerHeader from "./components/XWAppDrawerHeader";
import HomePage from "./stories/pages/HomePage";
import XWIntro from "./components/XWIntro/XWIntro";
import { Avatar } from '@mui/material';
import { logEvent } from '@firebase/analytics';

export const App = ({
  signedIn = false,
  user,
}) => {
  const ga = useAnalytics();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`GA recording pageview to ${location.pathname}`);
    } else {
      logEvent(ga, 'page_view', { page_location: location.pathname });
    }
  }, [location.pathname, ga, location]);

  const pages = [
    {
      navText: "Home",
      navIcon: <HomeIcon />,
      path: `/`,
    },
    {
      navText: "D.A.V.E.",
      navIcon: <Avatar src={`/vulnscan.png`} sx={{ width: 36, height: 36, margin: "-2px" }} />,
      navLink: `/`,
      pageTitle: "DAVE",
      path: "/dave",
      pages: [
        {
          navText: "Search Vulns",
          navIcon: <SearchIcon />,
          navLink: `${CVE_PATH}/search`,
          pageTitle: "Search",
          path: `${CVE_PATH}`,
        },
        {
          navText: "Inventory",
          navIcon: <AppsIcon />,
          pageTitle: "Inventory",
          path: `${INVENTORY_PATH}`,
          disabled: !signedIn,
        },
      ],
    },
    {
      navText: "Scantron",
      navIcon: <Avatar src={`/scantron.png`} sx={{ width: 36, height: 36, margin: "-2px" }} />,
      navLink: `/`,
      pageTitle: "Scantron",
      path: "/scantron",
      pages: [
        {
          navText: "Assets",
          navIcon: <ComputerIcon />,
          pageTitle: "Assets",
          path: `${ASSETS_PATH}`,
          disabled: !signedIn,
        },
        {
          navText: "Scan Results",
          navIcon: <RadarIcon />,
          pageTitle: "Scan Results",
          path: `${HOST_PATH}`,
          disabled: !signedIn,
        },
      ],
    },
    {
      navText: "Settings",
      navIcon: <SettingsIcon />,
      pageTitle: "Settings",
      path: `${SETTINGS_PATH}`,
      disabled: !signedIn,
    },
  ];

  return (
    <>
      {
        signedIn &&
        <XWIntro
          signedIn={signedIn}
          user={user}
          setDrawerOpen={setDrawerOpen}
        />
      }
      <Routes>
        <Route path={`/`} element={
          <MainNavigation
            pages={pages}
            title={`Xploit World`}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            AppBarMenuActions={<AppBarMenuItems />}
            DrawerHeader={<XWAppDrawerHeader />}
            Content={<Outlet />}
          />
        }>
          <Route index element={<HomePage />} />
          <Route path={`/dave/*`} element={<Outlet />}>
            <Route path={`${CVE_PATH}/*`} element={<CVEPage />} />
            <Route path={`${INVENTORY_PATH}/*`} element={<CPEInventoryPage />} />
          </Route>

          <Route path={`/scantron/*`} element={<Outlet />}>
            <Route path={`${ASSETS_PATH}/*`} element={<AssetsPage />} />
            <Route path={`${HOST_PATH}/*`} element={<HostPage />} />
          </Route>

          <Route path={`${SETTINGS_PATH}/*`} element={<SettingsPage />} />
          <Route path={`${LOGIN_PATH}`} element={<LoginPage />} />
          <Route path={`${NOTIFICATION_PATH}/*`} element={<NotificationPage />} />
        </Route>
      </Routes>
    </>
  );
}

export const withData = (WrappedComponent) => ({ ...props }) => {
  const { data: signInCheckResult } = useSigninCheck();

  return (
    <WrappedComponent
      signedIn={signInCheckResult?.signedIn || false}
      user={signInCheckResult?.user}
      {...props}
    />
  );
};

export default withData(App);
