import PropTypes from 'prop-types';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export const XWSelect = ({ type = "primary", label, options = [], ...props }) => {

  const makeOptions = (options) => {
    return (
      options.map(({ displayName, value, menuItemProps }, idx) => (
        <MenuItem
          key={idx}
          value={value}
          {...menuItemProps}
        >
          {displayName}
        </MenuItem>
      ))
    );
  };

  let variantProps = {};
  switch (type) {
    case "secondary":
      variantProps.variant = 'outlined';
      break;
    case "primary":
      break;
    default:
      break;
  };

  return (
    <FormControl fullWidth {...variantProps}>
      {label && <InputLabel id={`xwselect-${label}`} color={`secondary`}>{label}</InputLabel>}
      <Select
        labelId={label && `xwselect-${label}`}
        color={`secondary`}
        label={label}
        {...props}
      >
        {makeOptions(options)}
      </Select>
    </FormControl>
  );
};

XWSelect.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  options: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    value: PropTypes.any,
    menuItemProps: PropTypes.object,
  })),
};

XWSelect.defaultProps = {
  type: 'primary',
  options: [],
};

export default XWSelect;