import { doc, setDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import TourIcon from '@mui/icons-material/Tour';
import { useFirestoreDocData } from 'reactfire';
import DynamicSetting from "./DynamicSetting";

export const SETTING_PARAMS = {
  name: "show_tour",
  prettyName: "Show Tour",
  description: "Reset this to see the product tour.",
  type: 'boolean',
  icon: <TourIcon />,
  value: true,
};

export const ShowTourSetting = ({ loading, showTour, setShowTour }) => {

  return (
    <DynamicSetting
      loading={loading}
      value={showTour}
      setValue={setShowTour}
      setting={SETTING_PARAMS}
    />
  );
};

export const withShowTour = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const { data = {}, status } = useFirestoreDocData(settingRef);
  const value = data.hasOwnProperty(SETTING_PARAMS.name) ? data[SETTING_PARAMS.name] : SETTING_PARAMS.value;

  const setValue = (e) => {
    setDoc(settingRef, {[SETTING_PARAMS.name]: e}, {merge: true});
  };

  return (
    <WrappedComponent
      {...props}
      showTour={value}
      setShowTour={setValue}
      loading={status === 'loading'}
    />
  );

};

export default ShowTourSetting;