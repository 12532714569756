import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import {
  useConfirm,
} from "material-ui-confirm";
import { useSnackbar } from 'notistack';
import {
  Link,
} from 'react-router-dom';
import { Container } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import AddIcon from '@mui/icons-material/Add';

import { PATH as ADD_INVENTORY_PATH } from './AddCPEInventoryPage';

import withAuth from '../../components/withAuth';
import CPETable from '../../components/CPETable/CPETable';
import XWFab from '../../components/XWFab';

export const PATH = '/';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }
}));

export const CPEInventoryDetailsPage = ({ inventory = [], inventoryLoading, inventoryError, onDeleteProduct }) => {
  const classes = useStyles();

  return (
    <Container>
      <CPETable
        ContainerProps={{ id: `user-inventory` }}
        data={inventory}
        loading={inventoryLoading}
        error={inventoryError}
        totalRows={inventory.length}
        updateConfig={(update) => console.log(update)}
        onDelete={onDeleteProduct}
      />
      <XWFab
        className={classes.fab}
        aria-label={'add-to-inventory'}
        component={Link}
        to={`${ADD_INVENTORY_PATH}`}
        Icon={AddIcon}
        text={'Add Software'}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const invRef = collection(fs, `/users/${user?.uid}/inventory`);
  const { status: invStatus, data: inventory, error: invError } = useFirestoreCollectionData(invRef, { idField: '_id' });
  const loading = invStatus === 'loading';

  const handleDeleteProduct = (products) => {
    return confirm({ description: `Are you sure you would like to delete these ${products.length} products from your inventory?` })
      .then(() =>
        Promise.all(products.map(({ _id }) => {
          const removeRef = doc(fs, `users/${user.uid}/inventory/${_id}`);
          return deleteDoc(removeRef);
        }))
      )
      .then(() => enqueueSnackbar(`Product${products.length > 0 && 's'} successfullyremoved from your inventory.`, { variant: 'success' }));
  };

  return (
    <WrappedComponent
      {...props}
      inventory={inventory}
      inventoryLoading={loading}
      inventoryError={invError}
      onDeleteProduct={handleDeleteProduct}
    />
  );
};

export default withAuth(withData(CPEInventoryDetailsPage));