import { Skeleton, Typography } from "@mui/material";
import OpenPortsTable from "../../components/tables/OpenPortsTable";
import XWCrossfade from "../../components/XWCrossfade";

export const HostDetailsPanel = ({ host = {}, hostLoading, hostError, ports, portsLoading, portsError }) => {
  const { address = 'Loading...' } = host;

  return (
    <>
      <Typography gutterBottom variant={`h3`}>
        <XWCrossfade
          loading={hostLoading}
          FallbackComponent={<Skeleton sx={{width: 250}} />}
        >
          <span>{address}</span>
        </XWCrossfade>
      </Typography>
      <OpenPortsTable
        ports={ports}
        loading={portsLoading}
        error={portsError}
      />
    </>
  );
};

export default HostDetailsPanel;