import {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Tab,
  Tabs,
} from '@mui/material';
import {
  Box,
} from '@mui/system';

export const XWTabPanel = ({ content, sx, ...props }) => {
  return (
    <Box
      role="tabpanel"
      sx={[...(Array.isArray(sx) ? sx : [sx]),]}
      {...props}
    >
      {content}
    </Box>
  );
};

export const XWTabLayout = ({ tabs, TabProps = {}, TabContentProps }) => {
  const [selected, setSelected] = useState(0);

  const onTabChange = (e, newValue) => {
    setSelected(newValue);
  }

  return (
    <Box>
      <Box>
        <Tabs
          value={selected}
          textColor={'secondary'}
          indicatorColor={'secondary'}
          onChange={onTabChange}
          {...TabProps}
        >
          {tabs.map(({ navText, navIcon = null }, idx) =>
            <Tab key={idx} label={navText} />
          )}
        </Tabs>
      </Box>
      {tabs.map(({ content }, idx) =>
        <XWTabPanel
          key={idx}
          content={content}
          hidden={selected !== idx}
          {...TabContentProps}
        />
      )}
    </Box>
  );
};

XWTabLayout.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    navText: PropTypes.string,
    navIcon: PropTypes.element,
    content: PropTypes.element.isRequired,
  })),
  TabProps: PropTypes.object,
  TabContentProps: PropTypes.object,
};

XWTabLayout.defaultProps = {
  TabProps: {},
};

export default XWTabLayout;