import { useState } from "react";
import {
  InputAdornment,
  Stack,
} from "@mui/material";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

import XWTextField from "../../XWTextField";
import XWButton from "../../XWButton";

export const EmailSignup = ({ onSignup }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const [pass, setPass] = useState('');

  const [passConfirm, setPassConfirm] = useState('');
  const [passConfirmError, setPassConfirmError] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);

  const errors = !!emailError || !!passConfirmError;

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Stack
      spacing={2}
      sx={{ maxWidth: '300px' }}
    >
      <XWTextField
        variant={'secondary'}
        label={'E-mail Address'}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          if (!validateEmail(e.target.value)) {
            setEmailError("Invalid e-mail address.");
          } else {
            setEmailError(null);
          }
        }}
        error={!!emailError}
        helperText={emailError}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <AccountCircleIcon />
            </InputAdornment>
          )
        }}
      />
      <XWTextField
        variant={'secondary'}
        label={'Password'}
        type={`password`}
        value={pass}
        onChange={(e) => {
          setPass(e.target.value);
          if (!!passConfirm && e.target.value !== passConfirm) {
            setPassConfirmError("Passwords do not match.");
          } else {
            setPassConfirmError(null);
          }
        }}
        sx={{ marginTop: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <LockIcon />
            </InputAdornment>
          )
        }}
      />
      <XWTextField
        variant={'secondary'}
        label={'Confirm Password'}
        type={`password`}
        value={passConfirm}
        onChange={(e) => {
          setPassConfirm(e.target.value);
          if (e.target.value !== pass) {
            setPassConfirmError("Passwords do not match.");
          } else {
            setPassConfirmError(null);
          }
        }}
        error={!!passConfirmError}
        helperText={passConfirmError}
        sx={{ marginTop: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <LockIcon />
            </InputAdornment>
          )
        }}
      />
      <XWButton
        onClick={() => {
          setSubmitLoading(true);
          onSignup({email: email, password: pass});
        }}
        disabled={errors || !email || pass.length < 5 || !passConfirm || pass !== passConfirm || submitLoading}
      >
        Sign Up
      </XWButton>
    </Stack>
  );
};

export default EmailSignup;