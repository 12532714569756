import {
  useState,
} from 'react';
import { Paper } from '@mui/material';
import PaginatedDataTable from "../../sharedcomponents/PaginatedDataTable";
import DeleteIcon from '@mui/icons-material/Delete';
import XWToolbar from "../XWToolbar";
import { assetListTable } from "../../config/tables";

export const AssetListTable = ({ assets, onDelete, ContainerProps, loading, error, ...props }) => {
  const [selected, setSelected] = useState([]);

  return (
    <Paper {...ContainerProps}>
      {selected.length === 0 ?
        <XWToolbar dense title={'Assets'} />
        :
        <XWToolbar 
          title={`${selected.length} Selected`}
          actions={[{
            name: `Remove assets`,
            onClick: () => onDelete(selected),
            Icon: <DeleteIcon />,
          }]}
        />
      }
      <PaginatedDataTable
        {...props}
        loading={loading}
        error={error}
        data={assets}
        multiselect
        dataConfig={assetListTable}
        onChange={setSelected}
        showPaginationControlls={false}
      />
    </Paper>
  );
};

export default AssetListTable;