import React, {
  useState,
} from 'react';

import {
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SendIcon from '@mui/icons-material/Send';
import XWButton from '../XWButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  input: {
    width: '100%',
    padding: 0,
  },
  sendGrid: {
    minWidth: 125,
    textAlign: 'center',
    alignSelf: 'flex-end',
  }
}));

export const CommentInput = ({ onSubmit, loading = false, disabled }) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');

  return (
    <Paper className={classes.root}>
      <div>
        <Grid container spacing={1}>
          <Grid item xs>
            <TextField
              className={classes.input}
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              InputProps={{
                style: {
                  padding: 8
                }
              }}
              variant={'outlined'}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={1} className={classes.sendGrid}>
            <XWButton
              endIcon={loading ? <CircularProgress color={'secondary'} style={{width: 20, height: 20}}/> : <SendIcon />}
              disabled={disabled}
              onClick={() => {
                onSubmit(comment);
                setComment('');
              }}
            >
              Send!
            </XWButton>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default CommentInput;