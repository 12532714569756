import { Link } from 'react-router-dom';
import {
  Avatar,
  IconButton,
  darken,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import XWAvatarMessage from "../XWAvatarMessage";

import { PATH as NOTIFICATION_PAGE_PATH } from '../../pages/NotificationPage/NotificationPage';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    maxHeight: `40vh`,
    overflow: 'scroll',
    overflowY: 'scroll',
  },
  empty: {
    padding: theme.spacing(3),
  },
  read: {
    backgroundColor: darken(theme.palette.background.paper, .1)
  }
}));

export const NotificationItem = ({ notification, dismissNotification, markAsRead }) => {
  const classes = useStyles();

  const {
    _id,
    title = `Xploit World Update`,
    body,
    icon = `/vulnscan.png`,
    read = false,
    url,
  } = notification;

  return (
    <ListItem
      button
      className={`${read && classes.read}`}
      component={Link}
      to={`${url || `${NOTIFICATION_PAGE_PATH}/${_id}`}`}
      style={{ color: 'inherit', textDecoration: 'none' }}
      onClick={() => markAsRead(notification)}
    >
      {icon &&
        <ListItemIcon>
          <Avatar src={icon} />
        </ListItemIcon>
      }
      <ListItemText primary={title} secondary={body} />
      <ListItemSecondaryAction>
        <IconButton onClick={() => dismissNotification(notification)} size="large">
          <ClearIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const NotificationPanel = ({ notifications = [], dismissNotification, markAsRead }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {notifications.length === 0 ?
        <div className={classes.empty}>
          <XWAvatarMessage
            message={'You have no unread notifications.'}
          />
        </div>
        :
        <List>
          {notifications.map((notification, idx) =>
            <NotificationItem
              key={idx}
              notification={notification}
              dismissNotification={dismissNotification}
              markAsRead={markAsRead}
            />
          )}
        </List>
      }
    </Paper>
  )
};

export default NotificationPanel;