import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  addDoc,
  collection,
  doc,
  orderBy,
  query,
} from 'firebase/firestore';

export const useCveComments = (cveId) => {
  const fs = useFirestore();

  const cveRef = doc(fs, `cves`, cveId);
  const commentsRef = collection(cveRef, `comments`);
  const commentsQuery = query(commentsRef, orderBy("timestamp", "desc"))

  return [useFirestoreCollectionData(commentsQuery, { idField: "_id" }), (comment) => addDoc(commentsRef, comment)];
};