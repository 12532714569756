import { Avatar, Tooltip } from "@mui/material";

export const XWUserAvatar = ({userId = 'vulnscan', name, showTooltip, loading, ...props}) => {

  const avatar = <Avatar src={!loading ? `https://robohash.org/${userId}` : null} {...props} />;

  const displayTooltip = (children) => {
    if (showTooltip && name) 
      return <Tooltip title={`${name}`}>{children}</Tooltip>;
    return children;
  }

  return (
    <>{displayTooltip(avatar)}</>
  );
};

export default XWUserAvatar;