import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import XWStepper from '../XWStepper';
import VendorSelectionPanel from './VendorSelectionPanel';
import ProductSelectionPanel from './ProductSelectionPanel'
import {
  cpeVendors,
  cpeProducts,
} from '../../test/cpes';
//import NotificationSettingsPanel from './NotificationSettingsPanel';

export const InventorySelectionWizard = ({ onFinish = (products) => { }, disabled = false }) => {
  const [vendors, setVendors] = useState([]);
  const vendorOptions = cpeVendors;

  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  // The result of this wizard is the 'products' var is set, but it's frankly a bit ugly
  // So to clean things up and make it useable, this function is just going to 
  // reformat the products variable into something more digestable. It really just
  // needs to be mappable to a CPE, so it'll be an array of vendor/product objects.
  const formatResults = (products) => (
    products.map((item) => (
      {
        product: item.name,
        vendor: item.vendor,
        ...(item.prettyName && { productPretty: item.prettyName }),
        ...(item.secondaryText && { vendorPretty: item.secondaryText }),
      })
    )
  );

  useEffect(() => {
    setProductOptions(vendors.map((vendor) => (
      [
        ...cpeProducts[vendor.name].map((item) => (
          {
            ...item,
            secondaryText: vendor.prettyName,
            vendor: vendor.name,
          }
        ))
      ]
    )).flat());

    // TODO: This next line makes it so that if vendors change, all products are reset.
    // This could be done more intelligently.
    setProducts([]);
  }, [vendors]);

  const steps = [
    {
      title: "Select Vendors",
      content: <div><VendorSelectionPanel options={vendorOptions} vendors={vendors} setVendors={setVendors} style={{margin: 'auto'}} /></div>,
    },
    {
      title: "Select Products",
      content: <ProductSelectionPanel options={productOptions} products={products} setProducts={setProducts} />
    },
    // {
    //   title: "Notification Settings",
    //   content: <NotificationSettingsPanel />
    // },
  ];

  return (
    <XWStepper
      steps={steps}
      onFinish={() => onFinish(formatResults(products))}
      disabled={disabled}
    />
  );
};

InventorySelectionWizard.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

InventorySelectionWizard.defaultProps = {
};

export default InventorySelectionWizard;