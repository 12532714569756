import React from 'react';
import PropTypes from 'prop-types';

import XWAutocompleteMultiselect from '../XWAutocompleteMultiselect';

export const VendorSelectionPanel = ({ options, vendors, setVendors }) => {
  return (
    <XWAutocompleteMultiselect
      options={options}
      items={vendors}
      setItems={setVendors}
      label={'Select Vendors'}
    />
  );
};

VendorSelectionPanel.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      friendlyName: PropTypes.string,
      logo: PropTypes.string,
    }),
  ),
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      friendlyName: PropTypes.string,
      logo: PropTypes.string,
    }),
  ),
  setVendors: PropTypes.func.isRequired,
};

VendorSelectionPanel.defaultProps = {
};

export default VendorSelectionPanel;