import { useState } from "react";
import {
  Dialog,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import FilterListIcon from '@mui/icons-material/FilterList';

import { alpha, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { CVESearchSearchBox } from "./CVESearchSearchBox";
import { SHOW_SIDEBAR_BREAKPOINT } from "./CVESearch";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  },
  title: {
    flex: '1 1 100%',
  },
}));

export const CVESearchResultsToolbar = ({ onSearch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const showFilterButton = !useMediaQuery(theme.breakpoints.up(SHOW_SIDEBAR_BREAKPOINT));

  return <>
    <Dialog
      open={filterOpen}
      onClose={() => setFilterOpen(false)}
    >
      <CVESearchSearchBox
        onSearch={onSearch}
      />
    </Dialog>
    <Toolbar
      className={classes.root}
    >
      <Typography
        className={classes.title}
        variant={'h6'}
      >
        Vulnerabilities
      </Typography>
      {showFilterButton &&
        <Tooltip title={`Filter Results`}>
          <IconButton onClick={() => setFilterOpen(true)} size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  </>;
}

export default CVESearchResultsToolbar