import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, {
  ACTIONS,
  EVENTS,
} from 'react-joyride';
import {
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import XWIntroTooltip from './XWIntroTooltip';

import {
  introSteps,
} from './XWSteps';
import { withShowTour } from '../SettingsPanel/ShowTourSetting';

export const XWIntro = ({
  run = true,
  signedIn = true,
  setDrawerOpen,
  onClose = () => { },
  showTour = false,
  setShowTour = (e) => { },
  loading,
}) => {
  const navigation = useNavigate();
  const [step, setStep] = useState(0);
  const theme = useTheme();

  // TODO: If we ever want SSR, this will bork it
  const temporaryDrawer = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const steps = introSteps(signedIn, navigation, setDrawerOpen);

  // We want the drawer to be closed when we start
  useEffect(() => {
    if (temporaryDrawer) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen, temporaryDrawer]);

  const prepareStep = async ({ action, type, index }) => {

    if (action === ACTIONS.CLOSE) {
      const { after = () => { } } = steps[index];
      await after();

      onClose();
      setShowTour(false);
      setStep(0);
    }

    if (action === ACTIONS.START && type === EVENTS.TOUR_START) {
      const { before = () => { } } = steps[index];
      await before();
    }

    if ((action === ACTIONS.NEXT || action === ACTIONS.PREV) && type === EVENTS.STEP_AFTER) {
      const offset = action === ACTIONS.NEXT ? 1 : -1;
      const { before = () => { } } = steps[index + offset];
      const { after = () => { } } = steps[index];
      await after();
      await before();

      setStep(index + offset);
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      run={run && showTour && !loading}
      stepIndex={step}
      tooltipComponent={XWIntroTooltip}
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 1202
        },
      }}
      floaterProps={{ hideArrow: true }}
      callback={prepareStep}
    />
  )
};

export default withShowTour(XWIntro);