import { memo } from "react";
import {
  Route,
  Routes,
} from "react-router";
import AddCPEInventoryPage from "./AddCPEInventoryPage";

import CPEInventoryDetailsPage from "./CPEInventoryDetailsPage";

export const PATH = `inventory`;

const CPEInventoryPage = memo(() => {
  return (
    <Routes>
      <Route path={`add`} element={<AddCPEInventoryPage />} />
      <Route path={`/`} exact element={<CPEInventoryDetailsPage />} />
    </Routes>
  );
});

export default CPEInventoryPage;
