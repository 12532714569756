import XWTabLayout from "../XWTabLayout";

import SignupPanel from './SignupPanel/SignupPanel';
import LoginPanel from './LoginPanel/LoginPanel';

export const AuthenticationPanel = ({
  onLoginWithProvider,
  onSignupWithEmail,
  onLoginWithEmail,
  onForgotPassword,
}) => {

  const tabs = [
    {
      navText: 'Sign Up',
      content:
        <SignupPanel
          onLoginWithProvider={onLoginWithProvider}
          onSignupWithEmail={onSignupWithEmail}
        />,
    },
    {
      navText: 'Log In',
      content:
        <LoginPanel
          onLoginWithProvider={onLoginWithProvider}
          onLoginWithEmail={onLoginWithEmail}
          onForgotPassword={onForgotPassword}
        />,
    },
  ];

  return (
    <XWTabLayout
      tabs={tabs}
      TabProps={{ centered: true }}
      TabContentProps={{
        sx: {
          padding: 3,
        }
      }}
    />
  );
};

export default AuthenticationPanel;