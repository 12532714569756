import React, {
  useState,
} from 'react';

import PropType from 'prop-types';

import {
  Button,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';

export const XWStepper = ({ steps = [], onFinish, disabled = false }) => {
  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = (step) => {
    return steps[step].content;
  };

  return (
    <>
      <Paper elevation={0}>
        <Stepper
          activeStep={activeStep}
        >
          {steps.map((step, idx) =>
            <Step
              key={idx}
            >
              <StepLabel>{step.title}</StepLabel>
            </Step>
          )}
        </Stepper>
        <Container style={{paddingTop: 16, paddingBottom: 16}}>
          {getStepContent(activeStep)}
        </Container>
      </Paper>
      <Grid
        container
        justifyContent={`space-between`}
        style={{marginTop: 16}}
      >
        <Button
          color="secondary"
          disabled={activeStep === 0 || disabled}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Previous
        </Button>
        {activeStep === (steps.length - 1) ?
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep !== (steps.length - 1) || disabled}
            onClick={onFinish}
          >
            Finish
          </Button>
          :
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === (steps.length - 1) || disabled}
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Next
          </Button>
        }
      </Grid>
    </>
  );
};

XWStepper.propTypes = {
  steps: PropType.arrayOf(
    PropType.shape({
      title: PropType.string.isRequired,
      content: PropType.node.isRequired,
    }),
  ),
  onFinish: PropType.func.isRequired,
  disabled: PropType.bool,
};

XWStepper.defaultProps = {
  steps: [],
  disabled: false,
};

export default XWStepper;