import React from 'react';

import {
  Card,
  Avatar,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import XWAccordion from '../XWAccordion';
import { CVEDetailsCVSSSection } from './CVEDetailsCVSSSection';
import { CVEDetailsReferenceSection } from './CVEDetailsReferenceSection';
import { CVEDetailsApplicabilitySection } from './CVEDetailsApplicabilitySection';
import CVEDetailsDescriptionSection from './CVEDetailsDescriptionSection';
import { 
  getCvssScoreText, 
  getDescription, 
  getReferences, 
  getCveId 
} from '../../util/parseCVE';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
  },
  low: {
    backgroundColor: theme.palette.severity.low,
  },
  medium: {
    backgroundColor: theme.palette.severity.medium,
  },
  high: {
    backgroundColor: theme.palette.severity.high,
  },
  critical: {
    backgroundColor: theme.palette.severity.critical,
  },
  accordionTitle: {
    width: '30%',
    minWidth: 75,
    flexShrink: 0
  },
  accordion: {
    filter: 'brightness(80%)',
  },
  accordionSummary: {
    overflow: 'hidden',
  },
  accordionPreview: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accordionContent: {

  }
}));

const CVEDetailsHeader = ({ cveId, score, isStarred = false, loading }) => {
  const classes = useStyles();

  const getClassNameBySev = (cvss) => {
    if (score > 9) return classes.critical;
    if (score > 7.5) return classes.high;
    if (score > 4) return classes.medium;
    return classes.low;
  };

  return (
    <CardHeader
      title={loading ? <Skeleton><span>CVE-1234-1234124</span></Skeleton> : cveId}
      avatar={
        loading ?
          <Skeleton variant="circular"><Avatar /></Skeleton>
          :
          <Avatar className={getClassNameBySev(score)}>
            {score}
          </Avatar>
      }
      action={
        <IconButton aria-label="favorite" size="large">
          {isStarred ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      }
    >
    </CardHeader>
  );
};

const CVEDetailsContent = ({ loading, score, impact, configurations, ...cve }) => {

  const details = [
    {
      title: "Description",
      preview: "Technical details of the vulnerability",
      content: <CVEDetailsDescriptionSection description={getDescription(cve)} />,
    },
    {
      title: "CVSS Score and Vulnerability Types",
      preview: `${impact?.baseMetricV3?.cvssV3?.vectorString || impact?.baseMetricV2?.cvssV2?.vectorString}`,
      content: <CVEDetailsCVSSSection impact={impact} />,
    },
    {
      title: "Affected products",
      preview: "Expand this section to see impacted CPEs",
      content: <CVEDetailsApplicabilitySection configurations={configurations} />,
      disabled: !configurations?.nodes,
    },
    {
      title: "References",
      preview: "Sources that describe the vulnerability in depth",
      content: <CVEDetailsReferenceSection references={getReferences(cve)} />,
      disabled: getReferences(cve).length <= 0,
    }
  ];

  return (
    <CardContent>
      {details.map((detail, idx) => (
        <XWAccordion
          key={idx}
          loading={loading}
          disabled={detail.disabled}
          title={detail.title}
          preview={detail.preview}
          content={detail.content}
        />
      ))}
    </CardContent>
  );
};

export const CVEDetails = ({ cve = {}, loading }) => {
  const classes = useStyles();

  const cveId = getCveId(cve);
  const score = getCvssScoreText(cve);

  return (
    <Card className={classes.root}>
      <CVEDetailsHeader cveId={cveId} score={score} loading={loading} />
      <CVEDetailsContent {...cve} score={score} loading={loading} />
    </Card>
  );
};

export default CVEDetails;