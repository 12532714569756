import Add from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useTheme } from "@mui/system";
import { Link } from "react-router-dom";
import AssetListTable from "../../components/tables/AssetListTable";
import withAuth from "../../components/withAuth";
import XWFab from "../../components/XWFab";
import { useAssets } from "../../queries/useAssets";

import { PATH as ADD_PATH } from "./AddAssetsPage";

export const PATH = '/';

export const AssetsListPage = ({ assets, onDelete, loading, error }) => {
  const theme = useTheme();

  return (
    <Container>
      <AssetListTable
        onDelete={onDelete}
        assets={assets}
        loading={loading}
        error={error}
      />
      <XWFab
        sx={{ position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3) }}
        Icon={Add}
        text={'Add Asset'}
        component={Link}
        to={`${ADD_PATH}`}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const { uid } = user;
  const [{ data: assets, status, error }, , onDelete] = useAssets(uid);

  return (
    <WrappedComponent
      assets={assets}
      loading={status === 'loading'}
      error={error}
      onDelete={onDelete}
      {...props}
    />
  );
};

export default withAuth(withData(AssetsListPage));