import { Container } from "@mui/material";
import HostListTable from "../../components/tables/HostListTable";
import withAuth from "../../components/withAuth";
import { useHosts } from "../../queries/useHosts";
import XWFab from "../../components/XWFab";
import RadarIcon from '@mui/icons-material/Radar';
import { useFunctions } from 'reactfire';
import { httpsCallable } from "@firebase/functions";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

export const PATH = '/';

export const HostListPage = ({ hosts, loading, error, onRowClick, onScan }) => {
  const theme = useTheme();

  return (
    <Container>
      <HostListTable
        hosts={hosts}
        loading={loading}
        error={error}
        onRowClick={onRowClick}
      />
      <XWFab
        sx={{ position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3) }}
        Icon={RadarIcon}
        text={'Scan Assets'}
        onClick={() => onScan()}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { uid } = user;
  const [{ data: hosts = [], status, error }] = useHosts(uid);
  const functions = useFunctions();
  const remoteScan = httpsCallable(functions, 'startScan');

  const onScan = () => remoteScan()
    .then((res) => {
      const { data } = res;
      const { status, message } = data;
      if (status === 'error') enqueueSnackbar(`${message}`, { variant: 'error' })
    });

  return (
    <WrappedComponent
      hosts={hosts}
      loading={status === 'loading'}
      error={error}
      onRowClick={({ _id }) => navigation(_id)}
      onScan={onScan}
      {...props}
    />
  );
};

export default withAuth(withData(HostListPage));