import { Paper } from '@mui/material';
import PaginatedDataTable from "../../sharedcomponents/PaginatedDataTable";
import XWToolbar from "../XWToolbar";
import { hostListTable } from "../../config/tables";

export const HostListTable = ({ hosts, ContainerProps, onRowClick, ...props }) => {
  return (
    <Paper {...ContainerProps}>
      <XWToolbar title={'Scan Results'} />
      <PaginatedDataTable
        {...props}
        data={hosts}
        dataConfig={hostListTable}
        showPaginationControlls={false}
        onRowClick={onRowClick}
      />
    </Paper>
  );
};

export default HostListTable;