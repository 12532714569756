import {
  useFirestore,
  useFirestoreDocData,
} from 'reactfire';
import {
  doc,
} from 'firebase/firestore';

export const useHost = (userId, hostId) => {
  const fs = useFirestore();

  const hostRef = doc(fs, `users/${userId}/hosts/${hostId}`);
  return useFirestoreDocData(hostRef, { idField: "_id" });
};