import { 
  useSigninCheck, 
} from "reactfire";
import LoadingPage from "../pages/LoadingPage";
import LoginPage from "../pages/LoginPage";

export const withAuth = (WrappedComponent) => ({ ...props }) => {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === "loading")
    return <LoadingPage />
  if (signInCheckResult.signedIn === false)
    return <LoginPage />;

  return <WrappedComponent user={signInCheckResult.user} {...props} />;
};

export default withAuth;