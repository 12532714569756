import { useState, } from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import XWButton from '../XWButton';
import XWTextField from '../XWTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    padding: theme.spacing(2),
  },
  textField: {
    paddingBottom: theme.spacing(2),
  }
}));

export const CVESearchSearchBoxMinCvss = ({ searchParams, updateSearchParams = () => { } }) => {
  const classes = useStyles();
  const { minScore = '' } = searchParams;
  const [error, setError] = useState('');

  return (<div>
    <XWTextField
      className={classes.textField}
      label={`Min CVSS`}
      fullWidth
      error={!!error}
      helperText={error}
      value={minScore}
      placeholder={`CVSSv3`}
      variant={'secondary'}
      onChange={(e) => {
        console.log()
        if (e.target.value === '') {
          updateSearchParams({ minScore: null });
        } else {
          try {
            const parsed = parseFloat(e.target.value);
            if (parsed > 10 || parsed <= 0) {
              setError('CVSS score out of range');
            } else {
              setError('');
              updateSearchParams({ minScore: e.target.value })
            }
          } catch (e) {
            setError('CVSS must be a number');
            updateSearchParams({ minScore: null });
          }
        }
      }}
    />
  </div>);
};


export const CVESearchSearchBoxVendorProduct = ({ searchParams, updateSearchParams = () => { } }) => {
  const classes = useStyles();
  const { vendor = '', product = '' } = searchParams;

  return (
    <div>
      <XWTextField
        className={classes.textField}
        label={`Vendor`}
        fullWidth
        value={vendor}
        disabled={product !== ''}
        placeholder={'Vendor CPE String'}
        variant={'secondary'}
        onChange={(e) => {
          if (e.target.value === '') {
            updateSearchParams({ vendor: null });
          } else {
            updateSearchParams({ vendor: e.target.value });
          }
        }}
      />
      <XWTextField
        className={classes.textField}
        label={`Product`}
        fullWidth
        value={product}
        disabled={vendor !== ''}
        placeholder={'Product CPE String'}
        variant={'secondary'}
        onChange={(e) => {
          //setProduct(e.target.value);
          if (e.target.value === '') {
            updateSearchParams({ product: null });
          } else {
            updateSearchParams({ product: e.target.value });
          }
        }}
      />
    </div>
  );
};

export const CVESearchSearchBoxCategories = () => {
  const categories = [
    {
      text: 'RCE',
      description: 'Remote Code Execution',
    },
    {
      text: 'LPE',
      description: 'Local Priv Escalation',
    },
  ];

  return (
    <div>
      <List
        subheader={
          <ListSubheader>Categories</ListSubheader>
        }
      >
        {categories.map(({ text, description }, idx) => (
          <ListItem dense key={idx} >
            <ListItemIcon>
              <Checkbox edge="start" disabled />
            </ListItemIcon>
            <ListItemText primary={`${text}`} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export const CVESearchSearchBox = ({ onSearch, onReset }) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState({});

  const updateSearchParams = (values) => {
    const newState = {
      ...searchParams,
      ...values
    };

    // Remove values that are empty
    Object.keys(newState)
      .forEach((key) => (newState[key] === null || newState[key] === '') && delete newState[key])

    setSearchParams(newState);
  };

  return (
    <Paper className={classes.root}>
      <CVESearchSearchBoxCategories updateSearchParams={updateSearchParams} />
      <CVESearchSearchBoxVendorProduct updateSearchParams={updateSearchParams} searchParams={searchParams} />
      <CVESearchSearchBoxMinCvss updateSearchParams={updateSearchParams} searchParams={searchParams} />
      <div style={{ paddingTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <XWButton
          type="secondary"
          onClick={() => setSearchParams({})}
        >
          Reset
        </XWButton>
        <XWButton
          onClick={() => onSearch(searchParams)}
        >
          Search
        </XWButton>
      </div>
    </Paper>
  );
};