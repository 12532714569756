import { Container } from "@mui/material";
import AssetInput from "../../components/AssetInput/AssetInput";
import withAuth from "../../components/withAuth";
import { useAssets } from "../../queries/useAssets";

export const PATH = 'add';

export const AddAssetsPage = ({ addAsset }) => {

  return (
    <Container>
      <AssetInput
        onAdd={addAsset}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const { uid } = user;
  const [, addAsset] = useAssets(uid);

  return (
    <WrappedComponent
      addAsset={addAsset}
      {...props}
    />
  );
};

export default withAuth(withData(AddAssetsPage));