import React from 'react';

import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  listItem: {
    overflow: 'hidden',
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const CVEDetailsReferenceSection = ({ references }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {references.map((ref, idx) => (
        <ListItem
          key={idx}
          button
          component={Link}
          underline={"none"}
          href={`${ref.url}`}
        >
          <ListItemText
            primary={`${ref.name}`}
            primaryTypographyProps={{ className: classes.overflow }}
            secondary={`${ref.url}`}
            secondaryTypographyProps={{ className: classes.overflow }}
          />
          {ref.tags && ref.tags.map((tag, idx) =>
            <Chip key={idx} className={classes.chip} label={`${tag}`} color="secondary" />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default CVEDetailsReferenceSection;