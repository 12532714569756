import { useState } from "react";

import {
  InputAdornment,
  Stack,
} from "@mui/material";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

import XWTextField from "../../XWTextField";
import XWButton from "../../XWButton";

export const EmailSignin = ({ onLogin, onForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const [pass, setPass] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);

  const errors = !!emailError;

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Stack
      spacing={2}
      sx={{ maxWidth: '300px' }}
    >
      <XWTextField
        variant={'secondary'}
        label={'E-mail Address'}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          if (!validateEmail(e.target.value)) {
            setEmailError("Invalid e-mail address.");
          } else {
            setEmailError(null);
          }
        }}
        error={!!emailError}
        helperText={emailError}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <AccountCircleIcon />
            </InputAdornment>
          )
        }}
      />
      <XWTextField
        variant={'secondary'}
        label={'Password'}
        type={`password`}
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        sx={{ marginTop: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <LockIcon />
            </InputAdornment>
          )
        }}
      />
      <XWButton
        onClick={() => {
          setSubmitLoading(true);
          onLogin({email: email, password: pass});
        }}
        disabled={errors || !email || pass.length < 5 || submitLoading}
      >
        LogIn
      </XWButton>
      <XWButton 
        type={`secondary`}
        onClick={() => onForgotPassword(email)}
        disabled={errors || !email || true}
      >
        Forgot password?
      </XWButton>
    </Stack>
  );
};

export default EmailSignin;