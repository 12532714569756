import PropTypes from 'prop-types';

import {
  TextField,
} from "@mui/material";

export const XWTextField = ({ variant = "primary", ...props }) => {

  switch (variant) {
    case "secondary":
      return (
        <TextField color="secondary" {...props} />
      );
    case "primary":
    default:
      return (
        <TextField color="secondary" variant="standard" {...props} />
      );
  }
};

XWTextField.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

XWTextField.defaultProps = {
  variant: 'primary',
};

export default XWTextField;