import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
  orderBy,
  limit,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';

import {
  Container,
} from "@mui/material";

import CVESearch from "../../components/CVESearch/CVESearch";

import { cveSearchTableConfig } from "../../config/tables";
import { getCvssScoreText, getDescription } from '../../util/parseCVE';

const DEFAULT_ROWS_PER_PAGE = cveSearchTableConfig.pagination.defaultRowsPerPage;

export const CVESearchPage = ({ searchResults, rowsPerPage, page, loading, updatePagination, setSearchParams }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <CVESearch
        data={searchResults}
        dataConfig={cveSearchTableConfig}
        totalRows={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        loading={loading}
        updateConfig={updatePagination}
        onSearch={(e) => setSearchParams(e)}
        onRowClick={({ id }) => navigate(`/dave/cve/${id}`)}
      />
    </Container>
  );
};

const withData = (WrappedComponent) => ({ ...props }) => {
  const fs = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const [paginationParams, setPaginationParams] = useState({ page: 0, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const [startAtValue, setStartAtValue] = useState();
  const [orderDirection] = useState('desc');
  const [searchParams, setSearchParams] = useState({});

  const buildQueries = () => {
    const queries = [];
    const { vendor, product, minScore } = searchParams;

    minScore && queries.push(where('impact.baseMetricV3.cvssV3.baseScore', '>=', parseFloat(minScore)), orderBy('impact.baseMetricV3.cvssV3.baseScore'));
    vendor && queries.push(where('vendors', 'array-contains', vendor));
    product && queries.push(where('products', 'array-contains', product));

    queries.push(orderBy('cve.CVE_data_meta.ID', orderDirection));
    startAtValue && queries.push(startAfter(startAtValue));
    queries.push(limit(paginationParams.rowsPerPage));
    return queries;
  };

  const cveRef = collection(fs, 'cves/');
  const cveQuery = query(cveRef,
    ...buildQueries()
  );

  const { data: cves = [], status } = useFirestoreCollectionData(cveQuery, { idField: '_id' });
  const loading = status === "loading";

  // TODO: Paginating backwards isn't working. I think if you paginate backwards, you'll need to orderby the other direction.
  const updatePagination = (update) => {
    if ('page' in update) {
      if (update.page <= paginationParams.page) {
        enqueueSnackbar(`The back button isn't working yet.`, { variant: 'error' });
        // Kill the update
        update.page = paginationParams.page;
      } else {
        if (cves[paginationParams.rowsPerPage - 1]) {
          setStartAtValue(cves[paginationParams.rowsPerPage - 1].cve.CVE_data_meta.ID);
        } else {
          enqueueSnackbar(`You've reached the end of the data.`, { variant: `info` });
        };
      };
    };

    setPaginationParams({
      ...paginationParams,
      ...update,
    });
  };

  const formatData = () => {
    let newCves = cves.map((cve) => (
      {
        id: cve._id,
        cvss: getCvssScoreText(cve),
        description: getDescription(cve),
      }
    ));
    if (orderDirection === 'asc') {
      return newCves.reverse()
    }
    return newCves;
  };

  return (
    <WrappedComponent
      {...props}
      searchResults={formatData()}
      rowsPerPage={paginationParams.rowsPerPage}
      page={paginationParams.page}
      loading={loading}
      updatePagination={updatePagination}
      setSearchParams={setSearchParams}
    />
  );
};

export default withData(CVESearchPage);