import React from 'react';

import {
  Container,
  Typography,
} from '@mui/material';

export const CVEDetailsDescriptionSection = ({ description }) => {
  return (
    <Container>
      <Typography>{description}</Typography>
    </Container>
  );
};

export default CVEDetailsDescriptionSection;