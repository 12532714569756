import {
  Avatar,
} from "@mui/material";
import { GoogleAuthProvider } from "firebase/auth";
import XWButton from "../XWButton";

export const GoogleSigninButton = ({ onLoginWithProvider }) => {
  const provider = new GoogleAuthProvider();

  return (
    <XWButton
      sx={{ 
        backgroundColor: 'white', 
        color: 'black', 
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
      startIcon={
        <Avatar
          style={{ height: 24, width: 24 }}
          src={`/google.svg`}
        />
      }
      onClick={() => onLoginWithProvider(provider)}
    >
      Sign In with Google
    </XWButton>
  );
}

export default GoogleSigninButton;