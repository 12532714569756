import { memo } from "react";
import { 
  Route,
  Routes, 
} from "react-router";

import AddAssetsPage, {PATH as ADD_PATH} from "./AddAssetsPage";
import AssetsListPage, {PATH as LIST_PATH} from "./AssetsListPage";

export const PATH = 'assets';

const AssetsPage = memo(() => {
  return (
    <Routes>
      <Route path={`${LIST_PATH}`} element={<AssetsListPage />} />
      <Route path={`${ADD_PATH}`} element={<AddAssetsPage />} />
    </Routes>
  );
});

export default AssetsPage;
