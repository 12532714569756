import React from 'react';

import XWAutocompleteMultiselect from '../XWAutocompleteMultiselect';

export const ProductSelectionPanel = ({ options, products, setProducts }) => {
  return (
    <XWAutocompleteMultiselect 
      options={options} 
      items={products} 
      setItems={setProducts}
      label={'Select Products'}
    />
  );
};

ProductSelectionPanel.propTypes = {
};

ProductSelectionPanel.defaultProps = {
};

export default ProductSelectionPanel;