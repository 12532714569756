import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Link,
  useLocation,
} from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  useMediaQuery,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import { PageType } from '../config/types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentTemporary: {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  pages: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    flexGrow: 1,
  },
}));

const AppDrawerSubheader = ({ page, linkClicked, linkPrefix, depth }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItemButton
        disabled={page.disabled}
        id={`nav-${page.navText.toLowerCase().replaceAll(' ', '-').replaceAll('.', '')}`}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon>
          {page.navIcon}
        </ListItemIcon>
        <ListItemText primary={page.navText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <AppDrawerContent
          pages={page.pages}
          linkClicked={linkClicked}
          sx={{ pl: 4 }}
          ListProps={{ component: 'div', disablePadding: true }}
          ListItemProps={{ sx: { pl: (depth * 4) } }}
          depth={depth}
          linkPrefix={`${linkPrefix && `${linkPrefix}/`}${page.path}`}
        />
      </Collapse>
    </>
  );
};

const AppDrawerContent = ({ pages, linkClicked, ListItemProps, ListProps, linkPrefix = '', depth = 0 }) => {
  const { pathname: activePath } = useLocation();

  return (
    <List {...ListProps}>
      {pages.map((page, index) => (
        ((page.navLink || page.path) && page.navText) &&
        <React.Fragment key={index}>
          {page.pages ?
            <AppDrawerSubheader page={page} linkClicked={linkClicked} depth={depth + 1} linkPrefix={linkPrefix} />
            :
            <ListItem
              button
              component={Link}
              to={`${linkPrefix && `${linkPrefix}/`}${page.navLink || page.path}`}
              selected={(activePath === '/' && page.path === '/') || (activePath.includes(page.path) && page.path !== '/')}
              disabled={page.disabled}
              onClick={() => linkClicked()}
              id={`nav-${page.navText.toLowerCase().replaceAll(' ', '-').replaceAll('.', '')}`}
              {...ListItemProps}
            >
              <ListItemIcon>
                {page.navIcon}
              </ListItemIcon>
              <ListItemText primary={page.navText} />
            </ListItem>
          }
        </React.Fragment>
      ))}
    </List>
  );
};

const MainNavigation = ({
  pages,
  title,
  drawerOpen,
  setDrawerOpen,
  AppBarMenuActions,
  DrawerHeader,
  Content,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const drawerControlled = drawerOpen !== undefined;

  // TODO: If we ever want SSR, this will bork it
  const temporaryDrawer = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const [drawerState, setDrawerState] = useState(!temporaryDrawer);

  // Set controlled vs uncontrolled drawer
  const mobileOpen = drawerControlled ? drawerOpen : drawerState;
  const setMobileOpen = drawerControlled ? setDrawerOpen : setDrawerState;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(!temporaryDrawer);
  }, [temporaryDrawer, setMobileOpen]);

  return (
    <div className={!temporaryDrawer ? classes.root : undefined}>
      <AppBar position="fixed" className={`${classes.appBar} ${(mobileOpen && !temporaryDrawer) ? classes.appBarShift : undefined}`}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.actions}>
            {AppBarMenuActions}
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer
          variant={`${temporaryDrawer ? 'temporary' : 'persistent'}`}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={classes.drawer}>
            {DrawerHeader}
            <AppDrawerContent
              pages={pages}
              linkClicked={() => setMobileOpen(!temporaryDrawer)}
            />
          </div>
        </Drawer>
      </nav>
      <main className={`
        ${classes.content} 
        ${(mobileOpen && !temporaryDrawer) ? classes.contentShift : undefined}
        ${temporaryDrawer ? classes.contentTemporary : undefined}
      `}>
        <div className={classes.toolbar} />
        <div className={classes.pages}>
          {Content}
        </div>
      </main>
    </div>
  );
}

MainNavigation.propTypes = {
  pages: PropTypes.arrayOf(PageType).isRequired,
  title: PropTypes.string,
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  AppBarMenuActions: PropTypes.element,
  DrawerHeader: PropTypes.element,
  Content: PropTypes.element.isRequired,
};

MainNavigation.defaultProps = {
  pages: [],
};

export default MainNavigation;