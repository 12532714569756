import {
  useState,
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';

import PaginatedDataTable from '../../sharedcomponents/PaginatedDataTable';

import { cpeTableConfig } from '../../config/tables';
import XWToolbar from '../XWToolbar';
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export const CPETable = ({ Toolbar = <XWToolbar title={`Software Inventory`} />, onDelete, ContainerProps, ...props }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  return (
    <Paper className={classes.root} {...ContainerProps}>
      {selected.length === 0 ?
        Toolbar
        :
        <XWToolbar 
          title={`${selected.length} Selected`}
          actions={[{
            name: `Remove from inventory`,
            onClick: () => onDelete(selected),
            Icon: <DeleteIcon />,
          }]}
        />
      }
      <PaginatedDataTable
        {...props}
        multiselect
        dataConfig={cpeTableConfig}
        onChange={setSelected}
        showPaginationControlls={false}
      />
    </Paper>
  );
};

export default CPETable;