import { doc, updateDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import { useFirestoreDocData } from 'reactfire';
import DynamicSetting from "./DynamicSetting";
import MultilineChartIcon from '@mui/icons-material/MultilineChart';

export const SETTING_PARAMS = {
  name: "min_score",
  prettyName: "Minimum Score",
  description: "The minimum CVSS score to trigger a notification",
  type: 'float',
  onChange: console.log,
  icon: <MultilineChartIcon />,
  value: 7,
};

export const MinimumCvssScoreSetting = ({ loading, minimumCvss, setMinimumCvss }) => {
  return (
    <DynamicSetting
      setting={SETTING_PARAMS}
      value={minimumCvss}
      setValue={setMinimumCvss}
      loading={loading}
    />
  );
};

export const withMinimumCvssScore = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const { data = {}, status } = useFirestoreDocData(settingRef);
  const value = data[SETTING_PARAMS.name] || SETTING_PARAMS.value;

  const setValue = (e) => {
    updateDoc(settingRef, { [SETTING_PARAMS.name]: e });
  };

  return (
    <WrappedComponent
      {...props}
      minimumCvss={value}
      setMinimumCvss={setValue}
      loading={status === 'loading'}
    />
  );

};

export default MinimumCvssScoreSetting;