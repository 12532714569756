import {
  Container,
} from "@mui/material";
import { memo } from "react";

import XWAvatarMessage from "../components/XWAvatarMessage";

export const LoadingPage = memo(() => {

  return (
    <Container>
      <XWAvatarMessage message={`Loading...`} loading />
    </Container>
  );
});

export default LoadingPage;