import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
} from 'firebase/firestore';

export const useHosts = (userId) => {
  const fs = useFirestore();
  const hostsRef = collection(fs, `users/${userId}/hosts`);

  return [useFirestoreCollectionData(hostsRef, { idField: "_id" })];
};