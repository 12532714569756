import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import useTrace from '../util/useTrace';
import { useEffect } from 'react';

export const useAssets = (userId) => {
  const fs = useFirestore();

  // TODO: Should add a function to this that creates a new trace object. Should fix the rerenders and not require duplicate uids
  const t = useTrace('getAssets');
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const assetsRef = collection(fs, `users/${userId}/assets`);
  const assets = useFirestoreCollectionData(assetsRef, { idField: "_id" });
  const { getAssetsStatus} = assets;

  useEffect(() => {
    if (getAssetsStatus === 'loading') {
      t.start();
      console.log(`start: ${getAssetsStatus}`)
    } 

    return () => {
      if (getAssetsStatus === 'loading') {
        t.stop();
        console.log(`stop: ${getAssetsStatus}`);
      }
    }
  }, [getAssetsStatus, t]);

  const deleteAssets = (assets) => {
    return confirm({ description: `Are you sure you would like to delete these ${assets.length} assets?` })
      .then(() =>
        Promise.all(assets.map(({ _id }) => {
          const removeRef = doc(fs, `users/${userId}/assets/${_id}`);
          return deleteDoc(removeRef);
        }))
      )
      .then(() => enqueueSnackbar(`Asset${assets.length > 0 && 's'} successfully removed.`, { variant: 'success' }));
  };

  const addAsset = (asset) => {
    return addDoc(assetsRef, asset)
    .then(() => enqueueSnackbar(`Item added to your assets!`, { variant: `success`}));
  }

  return [assets, addAsset, deleteAssets];
};