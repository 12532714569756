import {
  useFirestore,
  useFirestoreDocData,
} from 'reactfire';
import {
  doc,
} from 'firebase/firestore';

import { Container } from '@mui/material';

import { useParams } from 'react-router';
import Notification from '../../components/Notification/Notification';
import withAuth from '../../components/withAuth';

export const PATH = `/:notificationId`;

export const NotificationDetailsPage = ({ notification, loading }) => {
  return (
    <Container>
      <Notification notification={notification} loading={loading} />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const { notificationId } = useParams();
  const { uid } = user;
  const fs = useFirestore();

  const notificationRef = doc(fs, `users`, `${uid}`, `notifications`, `${notificationId}`);
  const { data: notification, status } = useFirestoreDocData(notificationRef, { idField: '_id' });

  return (
    <WrappedComponent
      {...props}
      notification={notification}
      loading={status === 'loading'}
    />
  );
}

export default withAuth(withData(NotificationDetailsPage));