import PropTypes from 'prop-types';

import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  },
  title: {
    flex: '1 1 100%',
  },
}));

export const XWToolbar = ({ title, actions = [], toolbarProps, actionButtonProps }) => {
  const classes = useStyles();

  return (
    <Toolbar
      className={classes.root}
      {...toolbarProps}
    >
      <Typography
        className={classes.title}
        variant={'h6'}
      >
        {title}
      </Typography>
      {actions.map(({name, Icon, onClick}, idx) =>
        <Tooltip key={idx} title={`${name}`}>
          <IconButton onClick={() => onClick()} {...actionButtonProps} size="large">
            {Icon}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

XWToolbar.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    Icon: PropTypes.element.isRequired,
  })),
  toolbarProps: PropTypes.object,
  actionButtonProps: PropTypes.object,
};

XWToolbar.defaultProps = {
  actions: [],
};

export default XWToolbar