import {
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Comment from './Comment';
import { Skeleton } from '@mui/material';
import XWAvatarMessage from '../XWAvatarMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  emptyAvatar: {
    margin: 'auto',
    width: theme.spacing(15),
    height: theme.spacing(15,)
  },
  emptyText: {
    textAlign: 'center',
  }
}));

export const Comments = ({ comments = [], currentUser = null, loading, error }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant={'h5'}>Discussion</Typography>
      <List>
        {
          error ?
          <XWAvatarMessage message={'Something went wrong...'} />
          :
          loading ?
            Array(3).fill('').map((_item,idx) =>
              <ListItem key={idx}><Skeleton style={{ width: '100%', height: 60 }} /></ListItem>
            )
            :
            <>
              {comments.length === 0 &&
                <XWAvatarMessage message={'Nothing to see here yet...'} />
              }
              {comments.map((comment, idx) =>
                <Comment key={idx} {...comment} currentUser={currentUser} />
              )}
            </>
        }
      </List>
    </Paper>
  );
};

export default Comments;