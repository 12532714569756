import PropTypes from 'prop-types';
import { Fade } from "@mui/material";
import { useEffect, useState } from 'react';


export const XWCrossfade = ({ loading = true, children, FallbackComponent }) => {
  const [showChild, setShowChild] = useState(!loading);
  const [showFallback, setShowFallback] = useState(loading);

  useEffect(() => {
    if(loading) {
      setShowChild(false);
    } else {
      setShowFallback(false);
    }
  }, [loading]);

  return (
    <>
      <Fade
        unmountOnExit
        mountOnEnter={true}
        in={showChild}
        onExited={() => setShowFallback(true)}
      >
        <div>{children}</div>
      </Fade>
      <Fade
        unmountOnExit
        mountOnEnter={true}
        in={showFallback}
        onExited={() => setShowChild(true)}
      >
        <div>{FallbackComponent}</div>
      </Fade>
    </>
  );
};

XWCrossfade.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element.isRequired,
  FallbackComponent: PropTypes.element.isRequired,
};

XWCrossfade.defaultProps = {
  loading: false,
};

export default XWCrossfade;