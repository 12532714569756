import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { darken } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  selectedList: {
    maxWidth: 300,
    marginTop: theme.spacing(2),
    backgroundColor: darken(theme.palette.background.default, .083),
  },
}));

export const XWAutocompleteMultiselectSelectedItemList = ({ items, setItems }) => {
  const classes = useStyles();

  const handleDelete = (idx) => {
    const newItems = [...items];
    newItems.splice(idx, 1)
    setItems([...newItems]);
  };

  if (items.length === 0) return (<></>);

  return (
    <Paper className={classes.selectedList} elevation={4}>
      <List>
        {items.map((item, idx) => (
          <ListItem key={idx}>
            <ListItemText primary={item.prettyName || item.name} secondary={item.secondaryText} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(idx)}
                size="large">
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export const XWAutocompleteMultiselect = ({ options, items, setItems, label = "Select Items" }) => {
  return (
    <>
      <Autocomplete
        multiple
        fullWidth
        filterSelectedOptions
        disableClearable
        id="combo-box-vendor"
        value={items}
        onChange={(event, newItem) => setItems([...newItem])}
        options={options}
        getOptionLabel={(option) => option.prettyName || option.name}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        renderTags={() => <></>}
      />
      <XWAutocompleteMultiselectSelectedItemList
        items={items}
        setItems={setItems}
      />
    </>
  );
};

XWAutocompleteMultiselect.propTypes = {
};

XWAutocompleteMultiselect.defaultProps = {
};

export default XWAutocompleteMultiselect;