export const inventory = [
  {
    part: '*',
    vendor: 'microsoft',
    product: 'internet_explorer',
  },
  {
    part: '*',
    vendor: 'microsoft',
    product: 'visual_studio',
  },
  {
    part: '*',
    vendor: 'microsoft',
    product: 'windows_vista',
  },
  {
    part: '*',
    vendor: 'apple',
    product: 'ios',
  },
  {
    part: '*',
    vendor: 'linksys',
    product: 'wrt53g',
  },
];

export const cpeVendors = [
  {
    name: 'microsoft',
    prettyName: 'Microsoft',
    logo: null,
  },
  {
    name: 'apple',
    prettyName: 'Apple',
    logo: null,
  },
  {
    name: 'apache',
    prettyName: 'Apache',
    logo: null,
  },
  {
    name: 'cisco',
    prettyName: 'Cisco',
    logo: null,
  },
  {
    name: 'google',
    prettyName: 'Google',
    logo: null,
  },
  {
    name: 'mozilla',
    prettyName: 'Mozilla',
    logo: null,
  },
];

export const cpeProducts = {
  microsoft: [
    {
      name: 'ie',
      prettyName: 'Internet Explorer',
      logo: null,
    },
    {
      name: 'windows_server_2019',
      prettyName: 'Windows Server 2019',
      logo: null,
    },
    {
      name: 'windows_7',
      prettyName: 'Windows 7',
      logo: null,
    },
    {
      name: 'windows_server_2016',
      prettyName: 'Windows Server 2016',
      logo: null,
    },
    {
      name: 'windows_10',
      prettyName: 'Windows 10',
      logo: null,
    },
    {
      name: 'visual_studio_code',
      prettyName: 'Visual Studio Code',
      logo: null,
    },
    {
      name: 'exchange_server',
      prettyName: 'Microsoft Exchange Server',
      logo: null,
    },
    {
      name: 'office',
      prettyName: 'Microsoft Office',
      logo: null,
    },
  ],
  apple: [
    {
      name: 'itunes',
      prettyName: 'iTunes',
      logo: null,
    },
    {
      name: 'iphone_os',
      prettyName: 'iOS',
      logo: null,
    },
    {
      name: 'safari',
      prettyName: 'Safari',
      logo: null,
    },
    {
      name: 'quicktime',
      prettyName: 'Quicktime',
      logo: null,
    },
    {
      name: 'mac_os_x',
      prettyName: 'macOS',
      logo: null,
    },
  ],
  apache: [
    {
      name: 'tomcat',
      prettyName: 'Apache Tomcat',
      logo: null,
    },
    {
      name: 'http_server',
      prettyName: 'Apache HTTP Server',
      logo: null,
    },
  ],
  cisco: [
    {
      name: 'ios',
      prettyName: 'Cisco IOS',
      logo: null,
    },
  ],
  google: [
    {
      name: 'chrome',
      prettyName: 'Chrome',
      logo: null,
    },
    {
      name: 'chrome_os',
      prettyName: 'Chrome OS',
      logo: null,
    },
  ],
  mozilla: [
    {
      name: 'firefox',
      prettyName: 'Firefox',
      logo: null,
    },
    {
      name: 'thunderbird',
      prettyName: 'Thunderbird',
      logo: null,
    },
  ]
};