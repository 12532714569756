import { useEffect, useState } from 'react';

import {
  Avatar,
  CircularProgress,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.dark,
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: 'auto',
    marginBottom: theme.spacing(5),
  },
  text: {
    textAlign: 'center',
  },
  progress: {
    position: 'relative',
    margin: 'auto',
    display: 'block',
    bottom: 180,
  },
}));

export const XWAvatarMessage = ({ message, loading, animated = false, ...props }) => {
  const classes = useStyles();
  const [animatedIndex, setAnimatedIndex] = useState(0);

  useEffect(() => {
    if (animated) {
      const tick = () => {
        setAnimatedIndex(prev => prev + 1);
      }

      if (animatedIndex < message.length) {
        let addChar = setInterval(tick, 50);
        return () => clearInterval(addChar);
      }
    }
  }, [setAnimatedIndex, message, animatedIndex, animated]);

  return (
    <div {...props}>
      <Avatar
        className={classes.avatar}
        src={`/vulnscan.png`}
      />
      {message &&
        <Typography
          className={classes.text}
          variant={'body2'}
        >
          {animated ? message.substr(0, animatedIndex) : message }
        </Typography>
      }
      {loading &&
        <CircularProgress className={classes.progress} color={`primary`} style={{ width: 120, height: 120 }} />
      }
    </div>
  );
};

export default XWAvatarMessage;