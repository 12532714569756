import {
  useNavigate,
} from 'react-router-dom';
import {
  useFirestore,
} from 'reactfire';
import {
  collection,
  addDoc,
} from "firebase/firestore";

import {
  Container,
} from "@mui/material";
import {
  useConfirm,
} from "material-ui-confirm";
import { useSnackbar } from 'notistack';

import InventorySelectionWizard from "../../components/InventorySelection/InventorySelectionWizard";

import { PATH as INVENTORY_DETAILS_PATH } from './CPEInventoryDetailsPage';
import { PATH as INVENTORY_PATH } from './CPEInventoryPage';

import withAuth from '../../components/withAuth';

export const PATH = 'add';

export const AddCPEInventoryPage = ({ onSelectionComplete }) => {

  return (
    <Container>
      <InventorySelectionWizard onFinish={onSelectionComplete} />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const addItemsToInventory = (items) => (
    Promise.all(
      items.map((item) => {
        const invRef = collection(fs, `users/${user.uid}/inventory`);
        return addDoc(invRef, item);
      }))
  );

  const onSelectionComplete = (inventory) => confirm({
    description: "Would you like to add the selected items to your inventory?"
  })
    .then(() => (addItemsToInventory(inventory)))
    .then(() => navigate(`${INVENTORY_PATH}${INVENTORY_DETAILS_PATH}`))
    .then(() => enqueueSnackbar('Items successfully added to your inventory.', { variant: 'success' }))
    .catch((err) => console.log(`Something went wrong. ${err.message}`));

  return (
    <WrappedComponent
      {...props}
      onSelectionComplete={onSelectionComplete}
    />
  );
};

export default withAuth(withData(AddCPEInventoryPage));