import {
  Route,
  Routes,
} from "react-router";

import HostListPage, { PATH as HOST_LIST_PATH } from "./HostListPage";
import HostDetailsPage, { PATH as HOST_DETAILS_PATH } from "./HostDetailsPage";
import { memo } from "react";

export const PATH = 'host';

const HostPage = memo(() => {
  return (
    <Routes>
      <Route index path={`${HOST_LIST_PATH}`} element={<HostListPage />} />
      <Route path={`${HOST_DETAILS_PATH}`} element={<HostDetailsPage />} />
    </Routes>
  );
});

export default HostPage;