import { doc, setDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useFirestoreDocData } from 'reactfire';
import DynamicSetting from "./DynamicSetting";

export const SETTING_PARAMS = {
  name: "app_notify",
  prettyName: "Notify",
  description: "Get in-app notifications",
  type: 'boolean',
  icon: <NotificationsIcon />,
  value: false,
};

export const InAppNotificationsSetting = ({ loading, notificationsEnabled, setNotificationsEnabled }) => {

  return (
    <DynamicSetting
      loading={loading}
      value={notificationsEnabled}
      setValue={setNotificationsEnabled}
      setting={SETTING_PARAMS}
    />
  );
};

export const withInAppNotifications = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const { uid } = user;
  const settingRef = doc(fs, `users`, `${uid}`);
  const { data = {}, status } = useFirestoreDocData(settingRef);
  const value = data[SETTING_PARAMS.name] || SETTING_PARAMS.value;
  
  const setValue = (e) => {
    setDoc(settingRef, {[SETTING_PARAMS.name]: e}, {merge: true});
  };

  return (
    <WrappedComponent
      {...props}
      notificationsEnabled={value}
      setNotificationsEnabled={setValue}
      loading={status === 'loading'}
    />
  );

};

export default InAppNotificationsSetting;