import PropTypes from 'prop-types';

import {
  Button,
} from "@mui/material";

export const XWButton = ({ type = "primary", children, ...props }) => {

  switch (type) {
    case "secondary":
      return (
        <Button color="secondary" {...props}>{children}</Button>
      );
    case "primary":
    default:
      return (
        <Button color="primary" variant="contained" {...props} >{children}</Button>
      );
  }
};

XWButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node,
};

XWButton.defaultProps = {
  type: 'primary',
};

export default XWButton;