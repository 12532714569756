import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 160,
    backgroundImage: `url("/warp.gif")`,
    backgroundSize: 'cover',
    backgroundColor: 'black',
  },
  nested: {
      width: '100%',
      height: 160,
      backgroundImage: `url("/XW.png")`,
      backgroundSize: 'cover',
      backgroundPositionY: -28,
  }
}));

export const XWAppDrawerHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.nested}>

      </div>
    </div>
  );
};

export default XWAppDrawerHeader;