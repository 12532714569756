import makeStyles from '@mui/styles/makeStyles';
import Comments from './Comments';
import CommentInput from './CommentInput';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
  },
  inputSection: {
    marginTop: theme.spacing(2),
    color: 'red',
  }
}));

export const CommentSection = ({ comments = [], currentUser, loading, error, saveComment }) => {
  const classes = useStyles();

  return (
    <div>
      <Comments comments={comments} currentUser={currentUser} loading={loading} error={error}/>
      <div className={classes.inputSection}>
        <CommentInput loading={loading} disabled={loading || error || !currentUser } onSubmit={saveComment} />
      </div>
    </div>
  );
};

export default CommentSection;