import React from 'react';

import {
  Chip,
  Grid,
  Typography,
} from '@mui/material';

const capitalize = s => s && s[0].toUpperCase() + s.toLowerCase().slice(1)

const DetailItem = ({ text, value }) => {
  return (
    <>
      <Grid item xs={5} sm={3}><Typography>{text}</Typography></Grid>
      <Grid item xs={7} sm={3}><Chip label={capitalize(value)} /></Grid>
    </>
  );
};

export const CVEDetailsCVSSSection = ({ impact = {} }) => {
  const details = impact.baseMetricV3?.cvssV3 ||
    impact.baseMetricV2?.cvssV2 ||
    '?';

  const mapping = {
    // CVSSV1
    attackVector: "Vector",
    attackComplexity: "Complexity",
    privilegesRequired: "Privileges Required",
    userInteraction: "User Interaction",
    scope: "Scope",
    confidentialityImpact: "Confidentiality Impact",
    integrityImpact: "Integrity Impact",
    availabilityImpact: "Availability Impact",
    // CVSSV2
    accessVector: "Vector",
    authentication: "Privileges Required",
  };

  return (
    <Grid container spacing={2}>
      {Object.keys(details).map((key, idx) => (
        <React.Fragment key={key}>
          {mapping.hasOwnProperty(key) && <DetailItem text={mapping[key]} value={details[key]} />}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default CVEDetailsCVSSSection;