import { LoginProviders } from "../authentication/LoginProviders";

export const XWStep1a = () => {
  return (
    <>
      {`Hey! I've seen you around, but I don't think we've been introduced! I'm D.A.V.E. which stands for Detailed Analysis of Vulnerabilities and Exploits. It's my job to let you know when there's a new vulnerability for software that you care about! Unfortunately, my programming is pretty shoddy, so I'll need some help from you to figure it out.`}
    </>
  );
};

export const XWStep1b = ({ onLoginWithProvider }) => {
  return (
    <>
      {`Hello there! Sorry, my facial recognition is on the fritz. Go ahead and log in if you want. If not, that's okay, but everything works better when I can remember who you are. And unfortunately I am extremely forgetful.`}
      <LoginProviders
        style={{margin: 'auto', marginTop: 8, width: 'fit-content'}}
        onLoginWithProvider={onLoginWithProvider}
      />
    </>
  );
};

export const XWStep2a = () => {
  return (
    <>{`What a dashing avatar you have! I can't believe I forgot a face like that. Well, if you'd ever like to log out, you can manage your account from this button.`} </>
  );
};

export const XWStep2b = () => {
  return (
    <>{`No worries, you can always log in later by clicking on the avatar here in the top right corner. In the meantime, feel free to look up any vulnerabilities you are interested in with the search function on the left. Happy 'sploiting!`} </>
  );
};

export const XWStep3 = () => {
  return (
    <>{`I hope you don't mind, but I've added some of the most common applications that the meatbags tend to care about to your inventory. How does this list look to you? If I'm way off the mark, don't worry, you can always edit this yourself at any time from your inventory page!.`} </>
  );
};

export const XWStep4 = () => {
  return (
    <>{`I can tell you when a new vulnerability comes out for something in your inventory, but it's probably best if I only tell you about the important ones. I suggest limiting this to vulns with a CVSS above 7, but it's really up to you. You can change it now, if you like. If you aren't sure, it's safe to leave it alone for now.`} </>
  );
};

export const XWStep5 = () => {
  return (
    <>{`The Cybersecurity and Infrastructure Security Agency (CISA) keeps a list of Known Exploited Vulnerabilities (KEV). If a vulnerability that impacts you is on this list, you are probably going to want to know right away. I suggest turning this on, but I'll leave it up to you.`} </>
  );
};

export const XWStep6 = () => {
  return (
    <>{`Right now, I can send notifications straight to your device. In the future, I hope to be able to send e-mails and webhooks, but I'm frankly a little low on robo bucks these days. Check back soon for updates! In the meantime, I strongly advise turning this setting on, then hitting 'Allow' in the popup prompt.`} </>
  );
};

export const XWStep7 = () => {
  return (
    <>{`You can always look up any vulnerability you might be interested in by checking the search tab. Some search functionality is currently limited. You'll have to excuse my bugs while I wait for a firmware update.`} </>
  );
};

export const XWStep8 = () => {
  return (
    <>{`That's all for now! Welcome to Xploit World. We all hope you don't get hacked!`} </>
  );
};

export const introSteps = (signedIn, navigate, setDrawerOpen) => [
  {
    target: 'body',
    content: {
      title: 'Welcome!',
      body: signedIn ? <XWStep1a /> : <XWStep1b />,
    },
    disableBeacon: true,
  },
  {
    target: '#app-bar-user-options',
    content: {
      title: signedIn ? 'Welcome!' : 'Explore!',
      body: signedIn ? <XWStep2a /> : <XWStep2b />,
    },
    styles: { spotlight: { borderRadius: 50 } },
    spotlightPadding: 10,
  },
  {
    target: '#user-inventory',
    content: {
      title: 'Your Inventory',
      body: <XWStep3 />,
    },
    placement: 'bottom',
    before: async () => {
      await navigate(`/dave/inventory`);
      await new Promise((resolve) => setTimeout(resolve, 100));
    },
    spotlightPadding: 10,
  },
  {
    target: '#setting-min_score',
    content: {
      title: 'Minimum Score',
      body: <XWStep4 />,
    },
    before: async () => await navigate(`/settings`),
  },
  {
    target: '#setting-cisa_notify',
    content: {
      title: 'CISA Notifications',
      body: <XWStep5 />,
    },
    before: async () => await navigate(`/settings`),
    spotlightClicks: true,
  },
  {
    target: '#setting-app_notify',
    content: {
      title: 'App Notifications',
      body: <XWStep6 />,
    },
    before: async () => await navigate(`/settings`),
    spotlightClicks: true,
  },
  {
    target: '#nav-search-vulns',
    content: {
      title: 'Search Vulns',
      body: <XWStep7 />,
    },
    styles: { spotlight: { left: 0 } },
    before: async () => {
      await navigate(`/dave/cve/search`);
      setDrawerOpen(true);
      // This is needed to wait for the animation to complete so the spotlight is in the right position
      // I'm not sure why '1' works instead of the transition duration, but I don't
      // like to ask too many questions.
      //
      // After deploy, the timeout of 1 actually wasn't enough, so I've increased it! This feels like black 
      // magic, but we are all just doing our best.
      await new Promise((resolve) => setTimeout(resolve, 100));
    },
  },
  {
    target: 'body',
    content: {
      title: 'Good Luck!',
      body: <XWStep8 />,
    },
  },
];