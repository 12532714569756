import { memo } from "react";
import { 
  Route,
  Routes, 
} from "react-router";

import NotificationDetailsPage, {
  PATH as NOTIFICATION_DETAILS_PATH,
} from './NotificationDetailsPage';

export const PATH = '/notifications';

const NotificationPage = memo(() => {
  return (
    <Routes>
      <Route path={`${NOTIFICATION_DETAILS_PATH}`} element={<NotificationDetailsPage />} />
    </Routes>
  );
});

export default NotificationPage;