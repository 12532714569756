import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import {
  getMessaging,
  getToken,
} from 'firebase/messaging';
import {
  useSnackbar,
} from 'notistack';
import {
  Badge,
  IconButton,
  Popover,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import { SETTING_PARAMS } from '../SettingsPanel/InAppNotificationsSetting';

import { vapidKey } from '../../config/constants';
import useSetting from '../../queries/useSetting';

export const AppBarNotifications = memo(({
  notificationsEnabled = false,
  notifications = [],
  dismissNotification,
  markNotificationAsRead,
  getNotificationToken,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (notificationsEnabled) {
      getNotificationToken();
    }
  }, [notificationsEnabled, getNotificationToken]);

  const open = Boolean(anchorEl);

  return (
    <span>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
        <Badge
          badgeContent={notifications.length}
          max={9}
          color={'secondary'}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={open ? 'notification-panel' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NotificationPanel
          notifications={notifications}
          dismissNotification={dismissNotification}
          markAsRead={markNotificationAsRead}
        />
      </Popover>
    </span>
  );
});

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const fs = useFirestore();
  const messaging = getMessaging();
  const { enqueueSnackbar } = useSnackbar();
  const [{data: notificationsEnabled}] = useSetting(user, SETTING_PARAMS);
  
  const { uid } = user;

  const notiRef = collection(fs, `/users/${uid}/notifications`);
  const { data: notifications = [], error: notificationsError } = useFirestoreCollectionData(notiRef, { idField: '_id' });

  useEffect(() => {
    if (notificationsError) {
      enqueueSnackbar(`Error retrieving notifications.`, { variant: 'error' });
      console.log(notificationsError);
    }
  }, [notificationsError, enqueueSnackbar])

  const dismissNotification = useCallback((notification) => {
    const dismissRef = doc(notiRef, `${notification._id}`);
    deleteDoc(dismissRef);
  }, [notiRef]);

  const markNotificationAsRead = useCallback((notification) => {
    const { read, _id } = notification

    if (!read) {
      const notificationRef = doc(fs, `users/${uid}/notifications/${_id}`);
      updateDoc(notificationRef, { read: true });
    }
  }, [fs, uid]);

  const getNotificationToken = useCallback(() => {
    getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
      if (currentToken) {
        // send token to server
        console.log(`got a token`);

        const tokenRef = doc(fs, `/users/${uid}/notificationtokens`, currentToken);
        setDoc(tokenRef, { token: currentToken })
          .then((ref) => console.log('device enrolled for notifications'));

      } else {
        console.log(`request permissions branch`);
        // Requests permission
      }
    }).catch((err) => {
      console.log(`An error occurred getting the token.`, err);
    });
  }, [fs, messaging, uid]);

  return (
    <WrappedComponent
      {...props}
      notifications={notifications}
      notificationsEnabled={notificationsEnabled}
      dismissNotification={dismissNotification}
      markNotificationAsRead={markNotificationAsRead}
      getNotificationToken={getNotificationToken}
    />
  );
};

export default withData(AppBarNotifications);