import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import XWUserAvatar from '../XWUserAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    filter: 'brightness(120%)',
    marginTop: theme.spacing(2),
  },
  selfStyle: {
    textAlign: 'right',
  },
}));

const UserAvatar = ({ name, _id }, showTooltip = true) => (
  <Tooltip title={`${name}`}>
    <Avatar alt={`${name}`} src={`https://robohash.org/${_id}`} />
  </Tooltip>
);

export const Comment = ({ user, message, timestamp, currentUser = null }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <ListItem
        className={(currentUser === user._id) ? classes.selfStyle : null}
      >
        {(currentUser !== user._id) &&
          <ListItemAvatar>
            <UserAvatar {...user} />
          </ListItemAvatar>
        }
        <ListItemText
          primary={message}
          secondary={`${new Date(timestamp?.seconds * 1000).toUTCString() || 'No timestamp provided'}`}
        />
        {(currentUser === user._id) &&
          <ListItemAvatar>
            <XWUserAvatar name={user.name} userId={user._id} showTooltip />
          </ListItemAvatar>
        }
      </ListItem>
    </Paper>
  );
};

export default Comment;