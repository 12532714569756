import { 
  Route,
  Routes, 
} from "react-router";

import CVESearchPage from './CVESearchPage';
import CVEDetailsPage from './CVEDetailsPage';
import { memo } from "react";

export const PATH = 'cve';

const CVEPage = memo(() => {
  return (
    <Routes>
      <Route path={`search`} element={<CVESearchPage />} />
      <Route path={`:cveId`} element={<CVEDetailsPage />} />
    </Routes>
  );
});

export default CVEPage;
