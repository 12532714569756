import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import HostDetailsPanel from "../../components/HostDetailsPanel/HostDetailsPanel";
import withAuth from "../../components/withAuth";
import { useHost } from "../../queries/useHost";

export const PATH = ':hostId';

export const HostDetailsPage = ({ host = {}, loading, error }) => {
  const { ports = [] } = host;

  return (
    <Container>
      <HostDetailsPanel
        host={host}
        hostLoading={loading}
        hostError={error}
        ports={ports}
        portsLoading={loading}
        portsError={error}
      />
    </Container>
  );
};

export const withData = (WrappedComponent) => ({ user, ...props }) => {
  const { uid } = user;
  const { hostId } = useParams();
  const { data: host, status, error } = useHost(uid, hostId);

  return (
    <WrappedComponent
      host={host}
      //ports={ports}
      loading={status === 'loading'}
      error={error}
      {...props}
    />
  );
};

export default withAuth(withData(HostDetailsPage));