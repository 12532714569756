import {
  Divider,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import LoginProviders from '../LoginProviders';
import EmailSignup from './EmailSignup';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    margin: 'auto',
    textAlign: 'center',
  },
  socialButtons: {
    width: 200,
    height: 20,
  },
  section: {
    margin: 'auto',
  }
}));

export const LoginPanel = ({ onLoginWithProvider, onSignupWithEmail }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoginProviders onLoginWithProvider={onLoginWithProvider} />
      <Divider sx={{ marginTop: 2, marginBottom: 2 }}>or</Divider>
      <EmailSignup onSignup={onSignupWithEmail} />
    </div>
  );
};

export default LoginPanel;